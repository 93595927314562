<div class="bg-login" style="height: 100vh">
  <nav class="  navbar navbar-dark toolbar">
    <div class="container-fluid">
          <a class="navbar-brand" href="#">
            <img [src]="'assets/img/logoHmy.png'" alt=""  height="30px" class="d-inline-block align-text-center">
            HMY VS OTC Client
          </a>
          <span class=" navbar-text "> 
               powered by: 
               <img class="d-inline-block align-text-center" 
               height="30px"
                alt="Atbim Logo"
                [src]="'assets/img/logoAtbim.png'" />
             </span>
             
              </div>
        
      </nav>
    

  <div
    class="
      container-fluid
      d-flex
      align-items-center
      justify-content-center
      overflow-auto
      h-fill
    "
  >
    <div class="card w-25 ">
      <div class="g-0">
        <div class="card-body overflow-auto">
          <div class="card-title text-center pb-3"><h4>HMY OTC Login</h4></div>
          <div
            class="card-user d-flex align-items-center justify-content-center"
          >
            <i class="fa fa-user"></i>
          </div>

          

          <button (click)="login()"
            class="
              btn
              d-block
              btn-proyecto
              w-100
              mt-3
              d-flex
              justify-content-center
              align-items-center
            "
          >
            <i class="fa fa-windows px-1"></i> Login with Microsoft Azure AD
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
