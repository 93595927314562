import { Item } from './../item';
import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  SimpleChanges,
} from '@angular/core';
import { ForgeService } from '../forge.service';
// import { ProjectsComponent } from '../projects/projects.component';
import { DomSanitizer } from '@angular/platform-browser';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.css'],
})
export class ItemsComponent implements OnInit {
  @Input() items?: Item[];
  selectedItem?: Item;
  @Input() selectedProject;
  projectName;
  dataSelected: boolean = false;
  itemABorrar: Item = { objectName: '', thumbnail: '', urn: '', bucketKey: '' };
  clickItemEventSubscription: Subscription;

  constructor(private forge: ForgeService, private Sanitizer: DomSanitizer) {
    // this.clickItemEventSubscription = this.forge
    //   .getClickItemEvent()
    //   .subscribe((value) => {
    //     if (value == 'refresh-modelos') {
    //       this.getItems();
    //     }
    //   });
  }
  ngOnChanges(changes: SimpleChanges): void {
    // console.log(changes);
    if (changes.items) {
      this.dataSelected = true;
    }
  }
  ngOnInit(): void {}

  onSelect(item: Item): void {
    // console.log(item);
    this.forge.sendClickItemEvent(item);
    this.selectedItem = item;
  }
  permitirimagen(data) {
    return this.Sanitizer.bypassSecurityTrustResourceUrl(data);
  }
  abrirModalBorrado(item) {
    this.itemABorrar = item;
  }
  removeItem<Item>(arr: Array<Item>, value: Item): Array<Item> {
    const index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  borrarModelo(item: Item) {
    $('#deleteModal').hide();
    $('.modal-backdrop').hide();
    this.forge.deleteModel(item).subscribe((data) => {
      document.getElementById(
        'hmyload'
      ).innerHTML = `No hay modelos en el proyecto `;
      this.items = this.removeItem(this.items, this.itemABorrar);
    });
  }

  uploadFile() {
    var bucketName = this.selectedProject.bucketRealName;
    // console.log(bucketName);
    var files = <HTMLInputElement>$('#formFile')[0];
    if (files.files.length == 0) {
      $('#no-file').show();
      return;
    }
    $('#no-file').hide();
    var formData = new FormData();
    formData.append('fileToUpload', files.files[0]);
    formData.append('bucketKey', bucketName);
    $('#progress-bar').width('100%');
    $('#progress-bar').html(`Subiendo archivo...`);
    $('#progress-info').html('Subiendo archivo...');

    this.forge.sendModel(formData).subscribe((data: any) => {
      this.getPercentage(data.dictionary.urn);
    });
  }

  getItems(): void {
    this.forge.getItems(this.selectedProject).subscribe(
      (items) => {
        this.items = items;
        // console.log(items);
      },
      (err) => {
        document.getElementById(
          'hmyload'
        ).innerHTML = `No hay modelos en el proyecto `;
      }
    );
  }

  closeMod() {
    // this.forge.getItems(this.selectedProject).subscribe((data: any) => {
    //   this.forge.sendClickItemEvent('refresh-modelos');
    // });

    $('#addItemModal').hide();
    $('.modal-backdrop').hide();
  }

  getPercentage(urn) {
    this.forge
      .getPercentage({ urn: urn })
      .pipe(take(1))
      .subscribe((data) => {
        if ('complete' != data.dictionary.progress) {
          var numb = data.dictionary.progress.match(/\d/g).join('');
        } else {
          numb = 100;
        }

        $('#progress-info').html('Traduciendo archivo...');
        $('#progress-bar').width(numb + '%');
        $('#progress-bar').html(numb + '%');
        if (data.dictionary.progress == 'complete') {
          $('#progress-info').html('Terminado!');
          $('#progress-bar').width('100%');
          $('#progress-bar').html('Listo!');
          //add timer and close + refresh models after 2 seconds???
          this.getItems();
          setTimeout(this.closeMod, 2500);
        } else {
          this.getPercentage(urn);
        }
      });
  }

  testFunc() {
    this.projectName = document
      .getElementById('projectModelsTitle')
      ?.innerText.substring(11);
    // console.log('---->' + this.selectedProject.bucketRealName);
    // console.log('---->' + this.items);
  }
}
