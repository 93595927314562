<div>
  <div class="side-div scroll-div">
    <div class="scroll-div-header">
      <div id="prueba" class="title">PROYECTOS</div>
      <input
        id="psearchbox"
        type="search"
        (keyup)="onKey($event)"
        class="form-control c projects-search"
        placeholder="Escribe aquí para buscar."
      />
      <input
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#addProjectModal"
        class="btn btn-proyecto"
        value="+"
        (click)="openProjectModal()"
      />
    </div>

    <ul id="lista-proyectos" class="card-container">
      <div
        *ngFor="
          let project of filteredProjects
            | paginate : { itemsPerPage: 18, currentPage: page }
        "
        class="card btn-group m-3"
        target="_blank"
        rel="noopener"
        href=""
        [class.selected]="project === selectedProject"
      >
        <button class="btn btn-light big-size" (click)="onSelect(project)">
          {{ project.bucketName }}
        </button>

        <button
          data-bs-toggle="modal"
          data-bs-target="#deleteModalProyecto"
          class="btn btn-danger same-size"
          (click)="abrirModalBorrado(project)"
        >
          <i class="fa fa-trash"></i>
        </button>
      </div>
    </ul>
    <div class="has-text-centered">
      <pagination-controls (pageChange)="page = $event"></pagination-controls>
    </div>
  </div>
  <div class="side-div">
    <app-items [items]="items" [selectedProject]="selectedProject"></app-items>
  </div>
</div>
<!-- Modal -->
<div
  class="modal fade"
  id="deleteModalProyecto"
  tabindex="-1"
  aria-labelledby="deleteModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">¿Estás seguro?</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        ¿Estás seguro de querer borrar
        <b
          ><i>{{ proyectoABorrar.bucketName }}</i></b
        >?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal">
          Cancelar
        </button>
        <button type="button" class="btn btn-danger" (click)="deleteProject()">
          Borrar
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->
<div
  class="modal fade"
  id="addProjectModal"
  tabindex="-1"
  aria-labelledby="addProjectModal"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel2">CREAR PROYECTO</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="p-2 my-3">
          <label for="nombre-proyecto-nuevo2" class="form-label"
            >Nombre del proyecto</label
          >
          <input
            type="text"
            class="form-control mb-2"
            id="nombre-proyecto-nuevo2"
            placeholder="Escribe aquí el nombre del proyecto nuevo."
          />
        </div>
      </div>
      <div class="modal-footer">
        <input
          type="button"
          class="btn btn-proyecto"
          value="Crear proyecto"
          (click)="createProjectB()"
        />
        <button type="button" class="btn btn-warning" data-bs-dismiss="modal">
          Cancelar
        </button>
        <!-- <button type="button" class="btn btn-danger" (click)="deleteProject()">
          Borrar
        </button> -->
      </div>
    </div>
  </div>
</div>
