import { Attribute, Component, ElementRef, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { take } from 'rxjs/operators';
import { ForgeService } from '../forge.service';
import { ProjectsComponent } from '../projects/projects.component';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
})
export class MenuComponent implements OnInit {
  values = '';
  projects;
  selectedProject;
  constructor(private forge: ForgeService, private elementRef: ElementRef) {}

  onKey(event: any) {
    let input = event.target.value;
    let filter = input.toUpperCase();
    let lista = document.getElementById('lista');
    let componentes = lista.getElementsByTagName('a');
    for (let i = 0; i < componentes.length; i++) {
      let txtValue = componentes[i].innerHTML;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        componentes[i].style.display = '';
      } else {
        componentes[i].style.display = 'none';
      }
    }
  }
  ngOnInit(): void {
    this.getProjects();
  }
  createProject() {
    let valor = <HTMLFormElement>(
      document.getElementById('nombre-proyecto-nuevo')
    );
    let bucket;
    if (valor.value == '') {
      bucket = 'empty';
    } else {
      bucket = valor.value;
    }
    this.forge.createProject({ bucket: bucket }).subscribe((data) => {
      this.getProjects();
      this.forge.sendClickItemEvent('refresh-proyectos');
    });
  }
  uploadFile() {
    // var bucketName = <string>$('#selected-bucket').val();
    var bucketName = this.selectedProject.bucketRealName;
    console.log(bucketName);
    var files = <HTMLInputElement>$('#formFile')[0];
    if (files.files.length == 0) {
      $('#no-file').show();
      return;
    }
    $('#no-file').hide();
    var formData = new FormData();
    formData.append('fileToUpload', files.files[0]);
    formData.append('bucketKey', bucketName);
    $('#progress-bar').width('100%');
    $('#progress-bar').html(`Subiendo archivo...`);
    $('#progress-info').html('Subiendo archivo...');

    this.forge.sendModel(formData).subscribe((data: any) => {
      this.getPercentage(data.dictionary.urn);
    });
  }
  getPercentage(urn) {
    this.forge
      .getPercentage({ urn: urn })
      .pipe(take(1))
      .subscribe((data) => {
        if ('complete' != data.dictionary.progress) {
          var numb = data.dictionary.progress.match(/\d/g).join('');
        } else {
          numb = 100;
        }

        $('#progress-info').html('Traduciendo archivo...');
        $('#progress-bar').width(numb + '%');
        $('#progress-bar').html(numb + '%');
        if (data.dictionary.progress == 'complete') {
          $('#progress-info').html('Terminado!');
          $('#progress-bar').width('100%');
          $('#progress-bar').html('Listo!');
        } else {
          this.getPercentage(urn);
        }
      });
  }
  getProjects(): void {
    this.forge
      .getProjects()
      .subscribe((projects) => (this.projects = projects));
  }
  onSelect(project): void {
    console.log(project);
    this.selectedProject = project;
    let texto = <HTMLFormElement>document.getElementById('selected-bucket');
    texto.value = project.bucketName;
    texto.a;
    console.log(texto + ' --  ' + texto.value);
  }
}
