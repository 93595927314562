declare var Autodesk;
declare var THREE: any;
import * as XLSX from 'xlsx';
export class AudExtensionTree {
  divElement;
  arrayDbids;
  parentIds;
  viewer;
  aud;
  conflictElems;
  conflictObj;
  constructor(parentContainer, aud, conflictElems) {
    this.conflictElems = conflictElems;
    this.arrayDbids = [];
    this.parentIds = [];
    this.divElement;
    this.aud = aud;
    this.load(parentContainer);
    this.viewer = parentContainer;
    this.conflictObj = [];

    this.conflictElems.forEach((element) => {
      var delim1 = element.name.match(/\[/).index + 1;
      var delim2 = element.name.match(/\]/).index;
      this.conflictObj.push({
        projectName: this.viewer.model
          .getData()
          .loadOptions.bubbleNode.getRootNode()
          .children[0].name(),
        name: element.name,
        Id: element.name.slice(delim1, delim2),
        externalId: element.externalId,
        HMYLineal: element.properties[0].displayValue,
        HMYProfile: element.properties[1].displayValue,
        Descripción: element.properties[2].displayValue,
      });
    });
  }
  load(parent) {
    this.divElement = document.createElement('div');
    this.divElement.id = 'Aud-ExtensionTree';
    this.divElement.innerHTML = `<div style="max-height:calc(100% - 70px)" class="amon-shadow "><div id="aud-body-container" style="padding:0;" class="card-body"><div id="aud-list-container" class="list-group list-group-flush"></div></div></div>`;
    this.divElement.style.position = 'absolute';
    this.divElement.style.display = 'flex';
    this.divElement.style.height = 'calc(100% - 70px)';
    this.divElement.style.width = '150px';
    this.divElement.style.justifyContent = 'center';
    this.divElement.style.flexDirection = 'column';
    this.divElement.style.bottom = '-50px';
    this.divElement.style.right = '70px';
    this.divElement.style.zIndex = '999999';
    parent.clientContainer.firstElementChild.appendChild(this.divElement);
  }
  selectListElement(element) {
    var list = document.getElementById('aud-list-container');

    var lastSelection = list.querySelector('.atb-list-component-selected');
    if (lastSelection) {
      lastSelection.classList.remove('atb-list-component-selected');
      lastSelection.classList.add('atb-list-component');
    }
    element.classList.add('atb-list-component-selected');
    element.classList.remove('atb-list-component');
    this.onClickElement(element);
  }
  addListToTree(list) {
    list.forEach((element) => {
      this.addElementToTree(element);
    });
  }
  show() {
    document.getElementById('Aud-ExtensionTree').style.display = 'flex';
  }
  isShown(): boolean {
    if (document.getElementById('Aud-ExtensionTree').style.display == 'flex')
      return true;
    else return false;
  }
  hide() {
    document.getElementById('Aud-ExtensionTree').style.display = 'none';
  }

  addElementToTree(element) {
    var container = document.getElementById('aud-list-container');
    var linkToAdd = document.createElement('a');
    linkToAdd.classList.add(
      'list-group-item',
      'list-group-item-action',
      'atb-list-component',
      'px-0',
      'text-center'
    );
    linkToAdd.id = 'aud-li-element-' + element;
    linkToAdd.addEventListener('click', () =>
      this.selectListElement(linkToAdd)
    );
    linkToAdd.innerHTML = element;
    container.appendChild(linkToAdd);
  }

  async onClickElement(element) {
    switch (element.innerHTML) {
      case 'Marcar elementos':
        let color = new THREE.Vector4(255, 0, 0, 0.8);
        this.conflictElems.forEach((element) => {
          this.viewer.setThemingColor(
            element.dbId,
            color,
            this.viewer.model,
            true
          );
        });

        break;
      case 'Exportar XLSX':
        // var conflictObj = [];

        // this.conflictElems.forEach((element) => {
        //   var delim1 = element.name.match(/\[/).index + 1;
        //   var delim2 = element.name.match(/\]/).index;
        //   conflictObj.push({
        //     projectName: this.viewer.model
        //       .getData()
        //       .loadOptions.bubbleNode.getRootNode()
        //       .children[0].name(),
        //     name: element.name,
        //     Id: element.name.slice(delim1, delim2),
        //     externalId: element.externalId,
        //     HMYLineal: element.properties[0].displayValue,
        //     HMYProfile: element.properties[1].displayValue,
        //     Descripción: element.properties[2].displayValue,
        //   });
        // });

        this.downloadExcel(this.conflictObj);
        break;
      case 'Exportar JSON':
        const jsonData = JSON.stringify(this.conflictObj);
        var blob = new Blob([jsonData], { type: 'application/json' });
        var url = URL.createObjectURL(blob);
        window.open(url, '_blank');
        break;
      case 'Mandar informe':
        break;
    }
  }

  downloadExcel(data) {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'DataSheet.xlsx');
  }
}
