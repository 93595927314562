  <nav class="  navbar navbar-dark toolbar">
<div class="container-fluid">
      <a class="navbar-brand" href="#">
        <img [src]="'assets/img/logoHmy.png'" alt=""  height="30px" class="d-inline-block align-text-center">
        HMY VS OTC Client
      </a>

      <span class=" d-inline-block align-text-center navbar-text "> 
        
           powered by: 
           <img class="d-inline-block align-text-center" 
           height="30px"
            alt="Atbim Logo"
            [src]="'assets/img/logoAtbim.png'" />
            <a  data-bs-toggle="tooltip" data-bs-placement="top" title="Desconectarse" class="btn float-left m-0 p-0" style="mouse:pointer; font-size: 1.5em;" (click)="logout()"><i class=" fa fa-sign-out-alt"></i></a>
         </span>
         
          </div>
    
  </nav>
