declare var Autodesk;
export class HmyPropsPanel extends Autodesk.Viewing.UI.DockingPanel {
  constructor(viewer, container, id, title, options) {
    super(container, id, title, options);

    this.viewer = viewer;

    this.container.classList.add('docking-panel-container-solid-color-a');
    this.container.style.top = '10px';
    this.container.style.left = '10px';
    this.container.style.width = 'auto';
    this.container.style.height = 'auto';
    this.container.style.resize = 'auto';

    this.createTitleBar(title);

    var div = document.createElement('div');

    div.setAttribute('id', 'HmyPopsSubPanel');
    this.container.appendChild(div);

    var propsNavbar = this.createHtmlElement(
      'div',
      'propsNavbar',
      ['align-items-center', 'atb-teal', 'py-2', 'list-container'],
      '100%',
      'null',
      `<div class="atb-h4 text-center"></div>`
    );

    div.appendChild(propsNavbar);

    var hmyPropsList = this.createHtmlElement(
      'div',
      'props-list-container',
      ['list-container'],
      '100%',
      '100%',
      '<table><tr><td id="props1Title" class="list-key"></td><td id="props1Value"></td></tr><tr><td id="props2Title" class="list-key"></td><td id="props2Value"></td></tr><tr><td id="props3Title" class="list-key"></td><td id="props3Value"></td></tr><tr><td id="props4Title" class="list-key"></td><td id="props4Value"></td></tr></tr><tr><td id="props5Title" class="list-key"></td><td id="props5Value"></td></tr></tr><tr><td id="props6Title" class="list-key"></td><td id="props6Value"></td></tr></tr><tr><td id="props7Title" class="list-key"></td><td id="props7Value"></td></tr></table>'
    );

    div.appendChild(hmyPropsList);
  }

  createHtmlElement(type, id, classList, width, height, innerHTML) {
    var element = document.createElement(type);
    element.id = id;
    classList.forEach((Value) => {
      element.classList.add(Value);
    });
    element.style.width = width;
    element.style.height = height;
    element.innerHTML = innerHTML;
    return element;
  }
}
