// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  thisUrl: 'http:',
  apiUrl: 'https:',
  clientId: '', // This is your client ID
  authority: 'https:', // This is your tenant ID
  redirectUri: 'http:', // This is your redirect URI
};

// export const environment = {
//   production: false,
//   thisUrl: 'http://localhost:4200/',
//   apiUrl: 'https://localhost:5001/',
//   clientId: 'a003af60-0b28-4fca-af26-93e39d8516f9', // This is your client ID
//   authority:
//     'https://login.microsoftonline.com/9465c73c-ed0f-4a66-af47-2d26da68c8b1', // This is your tenant ID
//   redirectUri: 'http://localhost:4200/auth', // This is your redirect URI
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
