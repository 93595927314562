import { HttpClient, HttpHandler } from '@angular/common/http';
import { visitValue } from '@angular/compiler/src/util';
import { Injectable, Injector } from '@angular/core';
import { elementAt } from 'rxjs/operators';
//import { environment } from 'src/environments/environment';
import { envar } from 'src/environments/environment.prod';
import { ForgeService } from './forge.service';
// import {HorusExtensionPanel} from './horusextensionpanel'

declare var Autodesk;
declare var THREE;
export class KhepriExtensionPanel extends Autodesk.Viewing.UI.DockingPanel {
  constructor(viewer, container, id, title, options) {
    super(container, id, title, options);
    this.viewer = viewer;
    this.container.classList.add('docking-panel-container-solid-color-a');
    this.container.style.top = '10px';
    this.container.style.left = '10px';
    this.container.style.width = '600px';
    this.container.style.height = '400px';
    this.createTitleBar(title);
    var khepriMainPanel = document.createElement('div');
    khepriMainPanel.setAttribute('id', 'khepriMainPanel');
    khepriMainPanel.style.height = '100%';
    khepriMainPanel.style.width = '100%';
    var kheprinavbar = document.createElement('div');
    kheprinavbar.setAttribute('id', 'kheprinavbar');
    kheprinavbar.classList.add('align-items-center');
    kheprinavbar.classList.add('atb-teal', 'py-2');
    kheprinavbar.style.position = 'absolute';
    kheprinavbar.style.top = '50px';
    kheprinavbar.style.width = '100%';
    kheprinavbar.innerHTML = `<div class="atb-h4 text-center">Cambiar Color</div>`;
    khepriMainPanel.appendChild(kheprinavbar);
    var khepriListColors = document.createElement('div');
    khepriListColors.setAttribute('id', 'khepriListColors');
    khepriListColors.classList.add('align-items-center');
    khepriListColors.style.position = 'absolute';
    khepriListColors.style.top = '130px';
    khepriListColors.style.width = '100%';
    khepriListColors.style.right = '0';
    khepriListColors.style.height = 'calc(100% - 196px)';
    khepriListColors.classList.add('overflow-auto');
    khepriMainPanel.appendChild(khepriListColors);

    var khepriTopPanel = document.createElement('div');
    khepriTopPanel.setAttribute('id', 'khepriTopPanel');
    khepriTopPanel.style.width = '100%';
    khepriTopPanel.style.position = 'absolute';
    khepriTopPanel.style.top = '90px';
    khepriTopPanel.classList.add('d-flex');
    khepriTopPanel.style.backgroundColor = 'lightgray';
    khepriTopPanel.innerHTML = ` 
    <div class="col-1 m-0 p-0 d-flex justify-content-center align-items-center text-center atb-buscador">
      <i class="fa fa-search atb-h4  text-center" style = "vertical-align: middle;"></i>
    </div>
    <div class="m-0 p-0  atb-buscador col-11">
      <input id="findColor" type="text" class=" py-2 px-0  form-control atb-buscador" placeholder="Buscar Color">
    </div>`;

    khepriMainPanel.appendChild(khepriTopPanel);

    var khepriSubPanel = document.createElement('div');
    khepriSubPanel.setAttribute('id', 'khepriSubPanel');
    khepriSubPanel.classList.add(
      'd-flex',
      'justify-content-between',
      'align-items-center',
      'border-top',
      'bg-white',
      'g-0',
      'm-0',
      'p-0'
    );
    khepriSubPanel.style.height = '46px';
    khepriSubPanel.style.width = '100%';
    khepriSubPanel.style.position = 'absolute';
    khepriSubPanel.style.bottom = '20px';
    khepriSubPanel.style.left = '0px';
    khepriSubPanel.style.right = '0px';
    khepriMainPanel.appendChild(khepriSubPanel);
    this.container.appendChild(khepriMainPanel);
  }
}
async function sendEmail(filename, array) {
  (async () => {
    const rawResponse = await fetch(envar.apiUrl + 'api/hmy/cwa/colors', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiJzaGFwcGhteSIsImNlcnRzZXJpYWxudW1iZXIiOiJSbFI2dDdLd2dxS0xmcmpIIiwibmJmIjoxNjU4ODM4ODI1LCJleHAiOjE2NTg4NDI0MjUsImlhdCI6MTY1ODgzODgyNX0.iaw37wiQN-I31LEvQnlhpHtvdNrKduwfmph2Slono4o',
      },
      body: JSON.stringify({ colores: array, fichero: filename }),
    });
    const content = rawResponse;
    console.log(content, filename);
  })();
}
export function main(viewer, arrayTotal) {
  var listaColores = document.getElementById('khepriListColors');
  var panel = document.getElementById('khepriSubPanel');

  getListColors(listaColores);

  init(panel);

  var text = <HTMLInputElement>document.getElementById('colorText');
  var colorButton = <HTMLInputElement>document.getElementById('changeColor');
  var sendEmailButton = <HTMLInputElement>document.getElementById('sendEmail');
  var buscador = <HTMLInputElement>document.getElementById('findColor');
  sendEmailButton.innerHTML = `<i class="fa fa-envelope"></i>`;
  sendEmailButton.onclick = () => {
    sendEmail(
      viewer.model
        .getData()
        .loadOptions.bubbleNode.getRootNode()
        .children[0].name(),
      arrayTotal
    );
  };
  colorButton.innerHTML = ` <i class="fa fa-check"></i>`;
  colorButton.addEventListener('click', () =>
    eventoBoton(viewer, arrayTotal, text)
  );
  buscador.addEventListener('keyup', () => {
    let input = buscador.value;
    let filter = input.toUpperCase();
    let lista = document.getElementById('khepriListColors');
    let componentes = lista.getElementsByTagName('div');

    for (let i = 0; i < componentes.length; i++) {
      let txtValue = componentes[i].id;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        componentes[i].style.display = '';
      } else {
        componentes[i].style.display = 'none';
      }
    }
  });
}

function getListColors(listaColores) {
  let datos = localStorage.getItem('colors');
  JSON.parse(datos).forEach((element) => {
    createListColors(element, listaColores);
  });
  return datos;
}

function createListColors(element, listaColores) {
  var linea = document.createElement('div');
  linea.classList.add('atb-khepri-element');
  linea.setAttribute('id', element.code + ' ' + element.description);
  linea.onclick = () => {
    var lista = document.getElementById('khepriListColors');
    Array.prototype.forEach.call(lista.children, (nodo) => {
      var valores = (<HTMLElement>nodo).getElementsByClassName(
        'atb-reversed-teal-selected'
      );
      Array.prototype.forEach.call(valores, (val) => {
        (<HTMLElement>val).classList.remove('atb-reversed-teal-selected');
        (<HTMLElement>val).classList.add('atb-reversed-teal');
      });
    });
    let spanBadge = document.getElementById(element.code + '-badge');
    spanBadge.classList.add('atb-reversed-teal-selected');
    spanBadge.classList.remove('atb-reversed-teal');

    var text = <HTMLInputElement>document.getElementById('colorText');

    text.value = rgbToHex(element.r, element.g, element.b);
  };

  linea.innerHTML = `<span id="${element.code}-badge"class=" mr-1 badge badge-pill badge-primary atb-reversed-teal"> ${element.code} </span> ${element.description} <span style="background-color: rgb(${element.r},${element.g},${element.b});" class="float-end d-inline-block atb-round "></span>`;
  listaColores.appendChild(linea);
}
function init(panel) {
  panel.innerHTML = `
  <input id="colorText"  value="#699858" type="text" class= " form-control m-1 d-none"/>
  <button class=" m-1 atb-khepri-button-sendEmail btn" type="button" id="sendEmail"</button>  
  <button class=" m-1 atb-khepri-button-confirm btn" type="button" id="changeColor"</button>`;
}

function eventoBoton(viewer, arrayTotal, text) {
  var dbids = [];
  dbids = viewer.getSelection();
  console.log(viewer.getSelection());
  var colorHEX = text.value;

  addColorToArray(arrayTotal, colorHEX, dbids);
  setColor(viewer, arrayTotal);
}
export function setColor(viewer, arrayTotal) {
  arrayTotal.forEach((elemento) => {
    elemento.dbids.forEach((dbid) => {
      let color = hexToRgb(elemento.color);
      var colorFin = new THREE.Vector4(
        color.r / 255,
        color.g / 255,
        color.b / 255,
        1
      );
      viewer.setThemingColor(dbid, colorFin, viewer.model);
    });
  });
}

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}
function componentToHex(c) {
  var hex = c.toString(16);
  return hex.length == 1 ? '0' + hex : hex;
}

function rgbToHex(r, g, b) {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

async function addColorToArray(arrayTotal, color, dbids) {
  var code;
  code = checkCodeColor(color);
  var existsColor: Boolean = false;
  var indexColorRepeated = -1;
  for (let index = 0; index < arrayTotal.length; index++) {
    arrayTotal[index].dbids = arrayTotal[index].dbids.filter(
      (x) => !dbids.includes(x)
    );
    if (arrayTotal[index].color == color) {
      existsColor = true;
      indexColorRepeated = index;
    }
  }
  if (existsColor) {
    arrayTotal[indexColorRepeated].color = color;
    arrayTotal[indexColorRepeated].dbids =
      arrayTotal[indexColorRepeated].dbids.concat(dbids);
    arrayTotal[indexColorRepeated].code = code;
  } else {
    arrayTotal.push({
      color: color,
      dbids: dbids,
      code: code,
    });
  }

  for (let index = 0; index < arrayTotal.length; index++) {
    if (arrayTotal[index].dbids.length == 0) {
      arrayTotal = arrayTotal.splice(index, 1);
    }
  }
  console.log(arrayTotal);
}
function checkCodeColor(colorHex) {
  let colorRGB = hexToRgb(colorHex);
  let datos = localStorage.getItem('colors');
  let response = 'undefined';
  let codigo = JSON.parse(datos);
  codigo.forEach((element) => {
    if (
      colorRGB.r == element.r &&
      colorRGB.g == element.g &&
      colorRGB.b == element.b
    )
      response = element.code;
  });
  return response;
}
