<!-- Button trigger modal -->
<!-- Modal -->
<div
  class="modal fade bd-example-modal-xl"
  id="staticBackdrop"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
>
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel"></h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body min-size m-0 p-0">
        <div id="forgeViewer"></div>
      </div>
    </div>
  </div>
</div>
