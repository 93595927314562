import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
//import { environment } from 'src/environments/environment';
import { envar } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class HmyAuthService {
  private hmyTokenAskUrl: string = envar.apiUrl + 'api/hmy/oauth/check';
  
  constructor(private http: HttpClient) {}

  checkToken(auth_token): Observable<any> {
    const url = this.hmyTokenAskUrl;
    let headers = new HttpHeaders ({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${auth_token}`
    })
    return this.http.get(url, {headers: headers});
  }
}
