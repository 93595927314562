<div class="scroll-div-header">
  <div class="title">
    <p id="projectModelsTitle" i18n>MODELOS</p>
  </div>
  <input
    disabled="true"
    id="addModelButton"
    type="button"
    data-bs-toggle="modal"
    data-bs-target="#addItemModal"
    class="btn btn-model"
    value="Añadir Modelo"
    (click)="testFunc()"
  />
</div>
<div *ngIf="items" class="hmycard-container">
  <div
    [ngClass]="{
      'visually-hidden': !(items.length == 0 && dataSelected == true)
    }"
    id="hmyload"
    class="text-muted fst-italic"
  >
    No hay modelos cargados
  </div>
  <div
    *ngFor="let item of items"
    class="  "
    target="_blank"
    rel="noopener"
    href=""
    [class.selected]="item === selectedItem"
    class="card mx-3"
    style="width: 18rem"
  >
    <img
      [src]="permitirimagen('data:image/png;base64, ' + item.thumbnail)"
      class="card-img-top border-bottom"
      alt="..."
    />
    <div class="card-body py-2">
      <div class="card-text text-center fs-6 mb-0">{{ item.objectName }}</div>
    </div>
    <div class="card-footer btn-group m-0 p-0 border-0 bg-transparent">
      <button
        (click)="onSelect(item)"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
        target="_blank"
        rel="noopener"
        class="btn btn-primary no-radius-top-left"
      >
        <i class="fa fa-eye"></i>
      </button>
      <button
        data-bs-toggle="modal"
        data-bs-target="#deleteModal"
        (click)="abrirModalBorrado(item)"
        class="btn btn-danger no-radius-top-right"
      >
        <i class="fa fa-trash"></i>
      </button>
    </div>
  </div>
</div>
<!-- Modal -->
<div
  class="modal fade"
  id="deleteModal"
  tabindex="-1"
  aria-labelledby="deleteModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">¿Estás seguro?</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        ¿Estás seguro de querer borrar
        <b
          ><i>{{ itemABorrar.objectName }}</i></b
        >?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal">
          Cancelar
        </button>
        <button
          type="button"
          class="btn btn-danger"
          (click)="borrarModelo(itemABorrar)"
        >
          Borrar
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->
<div
  class="modal fade"
  id="addItemModal"
  tabindex="-1"
  aria-labelledby="addItemModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">AÑADIR MODELO</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="p-2 my-3">
          <p class="addModelTitle">
            Añadir modelo a proyecto {{ projectName }}
          </p>

          <label
            *ngIf="projectName != null"
            for="formFile"
            class="form-label mt-2"
            >Archivo</label
          >

          <input
            *ngIf="projectName != null"
            class="form-control mb-3"
            type="file"
            accept=".rvt, .nwd, .ifc, .zip "
            id="formFile"
          />
          <div
            id="no-file"
            style="display: none"
            class="mt-3 alert alert-danger"
          >
            Añade el archivo a subir
          </div>
        </div>
        <div class="go-bottom bg-white border-top">
          <div id="progress-info" class="mx-2 pb-1">Progress bar</div>

          <div class="progress mx-2" style="height: 30px">
            <div
              id="progress-bar"
              class="progress-bar bg-success"
              role="progressbar"
              style="width: 0%"
              aria-valuenow="50"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <input
          (click)="uploadFile()"
          id="add-model"
          type="button"
          class="btn btn-proyecto"
          value="Añadir modelo"
        />
        <!-- <div *ngIf="selectedProject == null" class="alert alert-warning">
            Selecciona un proyecto para poder añadir modelos
          </div> -->
        <button type="button" class="btn btn-warning" data-bs-dismiss="modal">
          Cancelar
        </button>
        <!-- <button
          type="button"
          class="btn btn-danger"
          (click)="testFunc()"
        >
          Borrar
        </button> -->
      </div>
    </div>
  </div>
</div>
