// const envar = {
//   thisUrl: 'http://localhost:4200/',
//   apiUrl: 'https://localhost:5001/',
//   clientId: 'e3118f0e-993c-45ed-9ae4-ae7314ba754d', // This is your client ID
//   authority:
//     'https://login.microsoftonline.com/9465c73c-ed0f-4a66-af47-2d26da68c8b1', // This is your tenant ID
//   redirectUri: 'http://localhost:4200/auth', // This is your redirect URI
// };

declare global {
  interface Window {
    runConfig: {
      thisUrl?: string;
      apiUrl?: string;
      clientId?: string;
      authority?: string;
      redirectUri?: string;
      materialModUrn?: string;
    };
  }
}

const envar = new (class EnvironmentVariables {
  thisUrl: string | undefined = window.runConfig.thisUrl
    ? `${window.runConfig?.thisUrl}`
    : process.env.THIS_URL;
  apiUrl: string | undefined = window.runConfig.apiUrl
    ? `${window.runConfig?.apiUrl}`
    : process.env.API_URL;
  clientId: string | undefined = window.runConfig.clientId
    ? `${window.runConfig?.clientId}`
    : process.env.AZURE_CLIENT_ID;
  authority: string | undefined = window.runConfig.authority
    ? `${window.runConfig?.authority}`
    : process.env.AZURE_AUTHORITY;
  redirectUri: string | undefined = window.runConfig.redirectUri
    ? `${window.runConfig?.redirectUri}`
    : process.env.REDIRECT_URL;
  materialModUrn: string | undefined = window.runConfig.materialModUrn
    ? `${window.runConfig?.materialModUrn}`
    : process.env.MATERIAL_MOD_URN;
})();
export { envar };
export default envar;
