import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalGuard, MsalService } from '@azure/msal-angular';
import { MsalauthService } from '../msalauth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor( private router:Router,private auth:MsalauthService) { }

  ngOnInit(): void {
    if(this.auth.isLoggedIn()){
      this.router.navigate(['main']);
    }
  }
  login(){
    this.router.navigate(['main']);
  }
}
