<div
  class="offcanvas offcanvas-start"
  tabindex="-1"
  id="menu-offcanvas"
  aria-labelledby="offcanvasExampleLabel"
>
  <div class="offcanvas-body m-0 p-0">
    <div class="separator p-2">
      CREAR PROYECTO
      <button
        type="button"
        class="btn-close btn-close-white text-reset float-end"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div class="p-2 my-3">
      <label for="nombre-proyecto-nuevo" class="form-label"
        >Nombre del proyecto</label
      >
      <input
        type="text"
        class="form-control mb-2"
        id="nombre-proyecto-nuevo"
        placeholder="Escribe aquí el nombre del proyecto nuevo."
      />
      <input
        type="button"
        class="btn btn-proyecto"
        value="Crear proyecto"
        (click)="createProject()"
      />
    </div>
    <div class="separator p-2">AÑADIR MODELO</div>
    <div class="p-2 my-3">
      <label for="nombre-proyecto-nuevomodelo" class="form-label"
        >Nombre del proyecto</label
      >
      <input
        type="search"
        (keyup)="onKey($event)"
        class="form-control"
        placeholder="Escribe aquí para buscar."
      />
      <div class="my-2 p-0 border" style="max-height: 150px; overflow: auto">
        <div id="lista" class="list-group list-group-flush">
          <a
            *ngFor="let project of projects"
            id="{{ project.bucketName }}"
            [class.selected]="project === selectedProject"
            (click)="onSelect(project)"
            class="list-group-item list-group-item-action"
            >{{ project.bucketName }}</a
          >
        </div>
      </div>
      <label for="selected-bucket" class="form-label mt-2"
        >Proyecto seleccionado</label
      >
      <input
        class="form-control mb-3"
        type="text"
        id="selected-bucket"
        disabled
      />
      <label
        *ngIf="selectedProject != null"
        for="formFile"
        class="form-label mt-2"
        >Archivo</label
      >

      <input
        *ngIf="selectedProject != null"
        class="form-control mb-3"
        type="file"
        accept=".rvt, .nwd, .ifc"
        id="formFile"
      />
      <input
        *ngIf="selectedProject != null"
        (click)="uploadFile()"
        id="add-model"
        type="button"
        class="btn btn-proyecto"
        value="Añadir modelo"
      />
      <div *ngIf="selectedProject == null" class="alert alert-warning">
        Selecciona un proyecto para poder añadir modelos
      </div>
      <div id="no-file" style="display: none" class="mt-3 alert alert-danger">
        Añade el archivo a subir
      </div>
    </div>
    <div class="go-bottom bg-white border-top">
      <div id="progress-info" class="mx-2 pb-1">Progress bar</div>

      <div class="progress mx-2" style="height: 30px">
        <div
          id="progress-bar"
          class="progress-bar bg-success"
          role="progressbar"
          style="width: 0%"
          aria-valuenow="50"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
    </div>
  </div>
</div>
