declare var Autodesk;
declare var THREE: any;
export class AmonExtensionTree {
  divElement;
  arrayDbids;
  parentIds;
  viewer;
  amon;
  constructor(parentContainer, amon) {
    this.arrayDbids = [];
    this.parentIds = [];
    this.divElement;
    this.amon = amon;
    this.load(parentContainer);
    this.viewer = parentContainer;
  }
  load(parent) {
    this.divElement = document.createElement('div');
    this.divElement.id = 'Amon-ExtensionTree';
    this.divElement.innerHTML = `<div style="max-height:calc(100% - 70px)" class="amon-shadow "><div id="amon-body-container" style="padding:0;" class="card-body"><div id="amon-list-container" class="list-group list-group-flush"></div></div></div>`;
    this.divElement.style.position = 'absolute';
    this.divElement.style.display = 'flex';
    this.divElement.style.height = 'calc(100% - 70px)';
    this.divElement.style.width = '150px';
    this.divElement.style.justifyContent = 'center';
    this.divElement.style.flexDirection = 'column';
    this.divElement.style.bottom = '110px';
    this.divElement.style.right = '70px';
    this.divElement.style.zIndex = '999999';
    parent.clientContainer.firstElementChild.appendChild(this.divElement);
  }
  selectListElement(element) {
    var list = document.getElementById('amon-list-container');

    var lastSelection = list.querySelector('.atb-list-component-selected');
    if (lastSelection) {
      lastSelection.classList.remove('atb-list-component-selected');
      lastSelection.classList.add('atb-list-component');
    }
    element.classList.add('atb-list-component-selected');
    element.classList.remove('atb-list-component');
    this.onClickElement(element);
  }
  addListToTree(list) {
    list.forEach((element) => {
      this.addElementToTree(element);
    });
  }
  show() {
    document.getElementById('Amon-ExtensionTree').style.display = 'flex';
  }
  isShown(): boolean {
    if (document.getElementById('Amon-ExtensionTree').style.display == 'flex')
      return true;
    else return false;
  }
  hide() {
    document.getElementById('Amon-ExtensionTree').style.display = 'none';
  }

  addElementToTree(element) {
    var container = document.getElementById('amon-list-container');
    var linkToAdd = document.createElement('a');
    linkToAdd.classList.add(
      'list-group-item',
      'list-group-item-action',
      'atb-list-component',
      'px-0',
      'text-center'
    );
    linkToAdd.id = 'amon-li-element-' + element;
    linkToAdd.addEventListener('click', () =>
      this.selectListElement(linkToAdd)
    );
    linkToAdd.innerHTML = element;
    container.appendChild(linkToAdd);
  }
  async onClickElement(element) {
    this.arrayDbids = [];
    this.viewer.showAll();

    switch (element.innerHTML) {
      case 'Filtrar por niveles':
      // this.viewer.loadExtension('Autodesk.AEC.LevelsExtension');
      // this.viewer  LevelsExtension this.amonPanel.show();

      case 'Desactivar techo':
        await this.viewer.model.getBulkProperties(
          null,
          ['CategoryId'],
          (res) => {
            // console.log(this.viewer.model.getData().metadata);
            res.forEach((element) => {
              //ID DE TECHOS
              if (element.properties[0].displayValue == -2000038) {
                this.arrayDbids.push(element.dbId);
              }
            });
            this.viewer.hide(this.arrayDbids);
          }
        );
        break;
      case 'Solo arquitectura':
        await this.viewer.model.getBulkProperties(
          null,
          ['CategoryId'],
          (res) => {
            res.forEach((element) => {
              if (
                //ID DE FURNITURE SYSTEM
                element.properties[0].displayValue == -2001100 ||
                //ID DE FURNITURE
                element.properties[0].displayValue == -2000080
              ) {
                this.arrayDbids.push(element.dbId);
              }
            });
            this.viewer.hide(this.arrayDbids);
          }
        );
        break;
      case 'Solo mobiliario':
        // console.log(this.viewer);
        await this.viewer.model.getBulkProperties(
          null,
          ['CategoryId'],
          (res) => {
            res.forEach((element) => {
              if (
                //IDS DE WALLS CEILINGS DOORS Y FLOORS
                element.properties[0].displayValue == -2000011 ||
                element.properties[0].displayValue == -2000038 ||
                element.properties[0].displayValue == -2000032 ||
                element.properties[0].displayValue == -2000023
              ) {
                this.arrayDbids.push(element.dbId);
              }
            });
            this.viewer.hide(this.arrayDbids);
            this.viewer.setGhosting(true);
          }
        );
        break;
      case 'Solo Luminarias':
        await this.viewer.model.getBulkProperties(
          null,
          ['CategoryId', 'parent'],
          (res) => {
            let hasParent = true;
            let currentId;
            res.forEach((element) => {
              if (
                // //ID DE FURNITURE SYSTEM
                // element.properties[0].displayValue == -2001100 ||
                // //ID DE FURNITURE
                // element.properties[0].displayValue == -2000080 ||
                // //IDS DE WALLS CEILINGS DOORS Y FLOORS
                // element.properties[0].displayValue == -2000011 ||
                // element.properties[0].displayValue == -2000038 ||
                // element.properties[0].displayValue == -2000032 ||
                // element.properties[0].displayValue == -2000023
                element.properties[0].displayValue == -2001120
              ) {
                this.arrayDbids.push(element.dbId);
              }
            });
            if (this.arrayDbids.length > 0) {
              //Using isolate instead of hide the rest
              this.viewer.isolate(this.arrayDbids);
              this.viewer.setGhosting(false);
            } else {
              console.log('No se encontraron luminarias');
            }
          }
        );
        break;
      case 'Solo Aparatos eléctricos':
        await this.viewer.model.getBulkProperties(
          null,
          ['CategoryId'],
          (res) => {
            res.forEach((element) => {
              if (
                //ID DE FURNITURE SYSTEM
                element.properties[0].displayValue == ''
              ) {
                this.arrayDbids.push(element.dbId);
              }
            });
            console.log('pending Id');
            //Using isolate instead of hide the rest
            // this.viewer.isolate(this.arrayDbids);
          }
        );
        break;
      case 'Filtrar por niveles':
        console.log('WIP');
        break;
    }

    // TESTING GET DATA FOR "Mobiliario, Luminarias, Aparatos Eléctricos"
    var testData = [];
    var testData2 = [];
    this.viewer.model.getBulkProperties(
      null,
      [
        'Code',
        'HMYLineal',
        'HMYProfile',
        'Descripción',
        'HMYEndowmentFrom',
        'Anfitrión',
      ],
      (res) => {
        res.forEach((element) => {
          // testData2.push(element.properties[0]);
          // element
          if (element.properties.length > 5) {
            // console.log(
            //   element.properties[0].displayValue +
            //     ' ---  ' +
            //     element.properties[5].displayValue
            // );
          }
          // element.properties[5].displayName
          // testData2.push(element);
        });
      }
    );
    // this.viewer.model.getProperties(9892, (res) => {
    //   console.log(res);
    // });
    // this.viewer.model.getProperties(10024, (res) => {
    //   console.log(res);
    // });
    // this.viewer.model.getProperties(10012, (res) => {
    //   console.log(res);
    // });
    // this.viewer.model.getProperties(10356, (res) => {
    //   console.log(res);
    // });
    var testSelect = [];

    this.viewer.model.getExternalIdMapping((data) =>
      Object.entries(data).forEach((element) => {
        if (
          element[0].includes('faac041b-9ff8-47c2-8e24-4eaaf5e9923a-003f1c')
        ) {
          // testSelect.push(element);
        } else {
          testSelect.push(element);
        }
      })
    );
    // console.log(testData2);
    // console.log(testSelect);
    testSelect.forEach((e) => {
      let color = new THREE.Vector4(0, 0.5, 0.73, 1);
      this.viewer.setThemingColor(e, color, this.viewer.model, true);
    });
  }
}
