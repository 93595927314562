import { ForgeService } from '../forge.service';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Project } from './../project';
import { Item } from './../item';
import { Subscription } from 'rxjs';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectsComponent implements OnInit {
  token: string;
  projects: Project[] = [];
  filteredProjects: Project[] = [];
  items: Item[] = [];
  selectedProject?: Project;
  proyectoABorrar: Project = {
    bucketName: '',
    bucketRealName: '',
    createdDate: null,
    policyKey: '',
  };
  page: number = 1;

  clickItemEventSubscription: Subscription;
  constructor(private forgeService: ForgeService) {
    this.clickItemEventSubscription = this.forgeService
      .getClickItemEvent()
      .subscribe((value) => {
        if (value == 'refresh-proyectos') {
          this.getProjects();
        }
      });
  }

  ngOnInit(): void {
    this.getProjects();
    // document.getElementById('psearchbox').setAttribute('display', 'hidden');

    // .addEventListener('search', function (event) {
    //   this.resetSearchField();
    //   console.log('canceeeel');
    // });
  }

  // onKey(event: any) {
  //   let input = event.target.value;
  //   let filter = input.toUpperCase();

  //   let lista = document.getElementById('lista-proyectos');

  //   let componentes = lista.getElementsByTagName('div');

  //   for (let i = 0; i < componentes.length; i++) {
  //     let txtValue = componentes[i].innerHTML;
  //     if (txtValue.toUpperCase().includes(filter)) {
  //       componentes[i].style.display = '';
  //     } else {
  //       componentes[i].style.display = 'none';
  //     }
  //   }
  // }

  onKey(event: any) {
    let input = event.target.value;
    let filter = input.toUpperCase();
    let fProj = [];

    let lista = this.projects;

    for (let i = 0; i < lista.length; i++) {
      let txtValue = lista[i].bucketName;
      if (txtValue.toUpperCase().includes(filter)) {
        fProj.push(lista[i]);
      } else {
      }
    }
    this.filteredProjects = fProj;
    this.page = 1;
  }

  // resetSearchField(): void {
  //   this.filteredProjects = [];
  //   this.page = 1;
  // }

  getProjects(): void {
    this.forgeService
      .getProjects()
      .subscribe(
        (projects) => (
          (this.projects = projects), (this.filteredProjects = projects)
        )
      );
  }

  getItems(): void {
    this.forgeService.getItems(this.selectedProject).subscribe(
      (items) => {
        this.items = items;
        // console.log(items);
      },
      (err) => {
        document.getElementById(
          'hmyload'
        ).innerHTML = `No hay modelos en el proyecto `;
      }
    );
  }
  abrirModalBorrado(proyecto) {
    this.proyectoABorrar = proyecto;
  }

  openProjectModal() {}

  createProjectB() {
    let valor = <HTMLFormElement>(
      document.getElementById('nombre-proyecto-nuevo2')
    );
    let bucket;
    if (valor.value == '') {
      bucket = 'empty';
    } else {
      bucket = valor.value;
    }

    // console.log(bucket);

    this.forgeService.createProject({ bucket: bucket }).subscribe((data) => {
      this.getProjects();
      this.forgeService.sendClickItemEvent('refresh-proyectos');
    });
    $('#addProjectModal').hide();
    $('.modal-backdrop').hide();
  }

  deleteProject() {
    $('#deleteModalProyecto').hide();
    $('.modal-backdrop').hide();

    this.forgeService
      .deleteProject(this.proyectoABorrar.bucketRealName)
      .subscribe((data) => {
        this.getProjects();
        this.items = [];
      });
  }
  onSelect(project: Project): void {
    this.items = [];
    document.getElementById('projectModelsTitle').innerText =
      'Modelos de ' + project.bucketName;
    // document.getElementById('addModelButton').setAttribute('disabled', 'false');
    document.getElementById('addModelButton').removeAttribute('disabled');

    document.getElementById(
      'hmyload'
    ).innerHTML = `<div class="spinner-border-sm spinner-border" role="status"></div> Loading...`;
    this.selectedProject = project;
    // console.log(this.selectedProject);
    this.getItems();
  }
}
