import { Component, OnInit, Output } from '@angular/core';

import { Subscription } from 'rxjs';
//import { environment } from 'src/environments/environment';
import { envar } from 'src/environments/environment.prod';
import { ForgeService } from '../forge.service';
import { HorusExtension } from 'src/app/horusextension';
import { ThotExtension } from 'src/app/thotextension';
import { MaterialSwatchExtensionUI } from 'src/app/material-swatch-ui';
import { MaterialSwatchExtension } from 'src/app/material-swatch';

declare var Autodesk: any;
declare var viewer;
@Component({
  selector: 'app-visor',
  templateUrl: './visor.component.html',
  styleUrls: ['./visor.component.css'],
})
export class VisorComponent implements OnInit {
  viewer: any;
  urliframe: string = '';
  seccionesHMY: [] = [];
  clickItemEventSubscription: Subscription;
  constructor(private forge: ForgeService) {
    this.clickItemEventSubscription = this.forge
      .getClickItemEvent()
      .subscribe((value) => {
        if (value != 'refresh-proyectos') {
          setTimeout(() => {
            this.launchViewer(value);
          }, 500);
        }
      });
  }

  ngOnInit(): void {
    Autodesk.Viewing.theExtensionManager.registerExtension(
      'HorusExtension',
      HorusExtension
    );
    Autodesk.Viewing.theExtensionManager.registerExtension(
      'ThotExtension',
      ThotExtension
    );
    Autodesk.Viewing.theExtensionManager.registerExtension(
      'MaterialSwatchExtensionUI',
      MaterialSwatchExtensionUI
    );
    Autodesk.Viewing.theExtensionManager.registerExtension(
      'MaterialSwatchExtension',
      MaterialSwatchExtension
    );

    var myModal = document.getElementById('staticBackdrop');
    myModal.addEventListener('hidden.bs.modal', function (event) {
      var viewer = document.getElementById('forgeViewer');
      viewer.innerHTML = '';
    });
  }

  launchViewer(item): void {
    // console.log(item);
    var modalTitle = document.getElementById('staticBackdropLabel');
    modalTitle.innerText =
      // item.bucketKey +
      // '  /  ' +
      item.objectName.slice(0, item.objectName.length - 4);
    let options = {
      env: 'AutodeskProduction',
      getAccessToken: this.getForgeToken,
    };
    var _this = this;
    Autodesk.Viewing.Initializer(options, () => {
      this.viewer = new Autodesk.Viewing.GuiViewer3D(
        document.getElementById('forgeViewer'),
        {
          extensions: [
            'Autodesk.DocumentBrowser',
            'Autodesk.AEC.LevelsExtension',
            // 'MaterialSwatchExtensionUI',
            // 'Autodesk.Viewing.MarkupsCore',
            // 'Autodesk.Viewing.MarkupsGui',
          ],
        }
      );
      this.viewer.start();
      viewer = this.viewer;

      var documentId = 'urn:' + item.urn;
      Autodesk.Viewing.Document.load(
        documentId,
        onDocumentLoadSuccess,
        onDocumentLoadFailure
      );
      var spinners = document.getElementsByClassName('forge-spinner');
      var spinner = spinners[0];
      spinner.classList.remove('forge-spinner');
      spinner.classList.add('loader');
      spinner.innerHTML =
        '<svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 620.61 213.24"><defs><style>.b{fill:#04225f;}</style></defs><path class="b" d="M351.2,89.63V48.29h19.51v102.91h-19.51v-43.94h-47.84v43.94h-19.51V48.29h19.51v41.34h47.84Z"/><path class="b" d="M461.48,97.29l17.2-49h28.33v102.91h-18.64v-31.36c0-19.27,.19-36.28,.58-51.02-2.12,7.23-4.72,15.13-7.8,23.7l-20.81,58.68h-16.04l-20.96-58.68c-3.66-10.7-6.22-18.5-7.66-23.41,.38,14.65,.58,31.56,.58,50.73v31.36h-18.79V48.29h28.33l17.78,50.01c1.16,3.37,4.09,12.67,8.82,27.89,3.08-10.7,6.12-20.33,9.11-28.91Z"/><path class="b" d="M587.8,66.79l11.27-18.5h21.53l-38.59,62.29v40.61h-19.51v-40.61l-38.88-62.29h22.11l11.42,18.64c9.25,15.42,14.4,24.09,15.47,26.02,2.6-4.62,7.66-13.34,15.18-26.16Z"/><g><polygon class="b" points="68.94 196.18 68.94 116.57 0 76.77 0 88.14 59.09 122.26 59.09 190.49 68.94 196.18"/><polygon class="b" points="98.49 213.24 103.42 210.4 103.42 102.36 196.98 48.33 196.98 42.65 192.06 39.8 98.49 93.83 4.92 39.8 0 42.65 0 48.33 93.57 102.36 93.57 210.4 98.49 213.24"/><polygon class="b" points="196.98 116.57 162.51 136.47 162.51 176.28 172.36 170.59 172.36 142.16 196.98 127.94 196.98 116.57"/><polygon class="b" points="0 127.94 24.62 142.16 24.62 170.59 34.47 176.28 34.47 136.47 0 116.57 0 127.94"/><polygon class="b" points="137.89 122.26 196.98 88.14 196.98 76.77 128.04 116.57 128.04 196.18 137.89 190.49 137.89 122.26"/><polygon class="b" points="98.49 65.39 167.43 25.59 157.59 19.9 98.49 54.02 39.4 19.9 29.55 25.59 98.49 65.39"/><polygon class="b" points="98.49 25.59 132.96 5.69 123.11 0 98.49 14.22 73.87 0 64.02 5.69 98.49 25.59"/></g></svg>';
    });

    function onDocumentLoadSuccess(doc) {
      var viewables = doc.getRoot().getDefaultGeometry();
      viewer.loadDocumentNode(doc, viewables).then((i) => {
        viewer.setTheme('light-theme');
        viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, () => {
          viewer.loadExtension('HorusExtension');
          viewer.loadExtension('ThotExtension');
        });
      });
    }

    function onDocumentLoadFailure(viewerErrorCode) {
      console.error('onDocumentLoadFailure() - errorCode:' + viewerErrorCode);
    }
  }

  getForgeToken(callback) {
    fetch(envar.apiUrl + 'api/forge/oauth/token').then((res) => {
      res.json().then((data) => {
        callback(data.dictionary.access_token, data.dictionary.expires_in);
      });
    });
  }
}
var viewer;
