import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  PublicClientApplication,
  InteractionType,
  IPublicClientApplication,
} from '@azure/msal-browser';

import {
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
} from '@azure/msal-angular';

import { NavbarComponent } from './navbar/navbar.component';
import { ProjectsComponent } from './projects/projects.component';
import { ItemsComponent } from './items/items.component';
import { VisorComponent } from './visor/visor.component';
import { MenuComponent } from './menu/menu.component';
import { ExternalViewerComponent } from './external-viewer/external-viewer.component';

import { AppWrapperComponent } from './app-wrapper/app-wrapper.component';
import { HorusExtension } from './horusextension';
import { ForgeService } from './forge.service';
import { KhepriExtensionPanel } from './khepriextensionpanel';
import { LoginComponent } from './login/login.component';
import { Configuration } from 'msal';
//import { environment } from 'src/environments/environment';
import { envar } from 'src/environments/environment.prod';
import { NgxPaginationModule } from 'ngx-pagination';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    ProjectsComponent,
    ItemsComponent,
    VisorComponent,
    MenuComponent,
    ExternalViewerComponent,
    AppWrapperComponent,
    LoginComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    NgxPaginationModule,
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: envar.clientId, // This is your client ID
          authority: envar.authority, // This is your tenant ID
          redirectUri: envar.redirectUri, // This is your redirect URI
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
        },
      }),
      {
        // MSAL Guard Configuration
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: ['api://' + envar.clientId + '/access_as_user'],
        },
        loginFailedRoute: '/login',
      },
      {
        // MSAL Interceptor Configurations
        interactionType: InteractionType.Redirect,

        protectedResourceMap: new Map<string, Array<string> | null>([
          /* [environment.apiUrl + 'api/hmy/oauth/check', null],
          [environment.apiUrl + 'api/hmy/cwa/colors', null],
          [
            environment.apiUrl,
            ['api://a003af60-0b28-4fca-af26-93e39d8516f9/access_as_user']
          ], */
          [envar.apiUrl + 'api/hmy/oauth/check', null],
          [envar.apiUrl + 'api/hmy/cwa/colors', null],
          [envar.apiUrl, ['api://' + envar.clientId + '/access_as_user']],
        ]),
      }
    ),
  ],
  providers: [
    ForgeService,
    HttpClient,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
