import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ExternalViewerComponent } from './external-viewer/external-viewer.component';
import { AppWrapperComponent } from './app-wrapper/app-wrapper.component';
import { LoginComponent } from './login/login.component';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'external-viewer', component: ExternalViewerComponent },
  { path: 'main', component: AppWrapperComponent, canActivate: [MsalGuard] },
  { path: 'auth', component: MsalRedirectComponent },
  { path: 'login', component: LoginComponent,canActivate:[MsalGuard]}

];

const isIframe = window !== window.parent && !window.opener && window.location.href.indexOf("external-viewer") == -1 ;
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: !isIframe ? 'enabled' : 'disabled', // Don't perform initial navigation in iframes
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
