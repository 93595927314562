
declare var Autodesk;
export class HorusExtensionPanel extends Autodesk.Viewing.UI.DockingPanel {
    constructor(viewer, container, id, title, options) {
        super(container, id, title, options);
        this.viewer = viewer;

        this.container.classList.add('docking-panel-container-solid-color-a');
        this.container.style.top = "10px";
        this.container.style.left = "10px";
        this.container.style.width = "auto";
        this.container.style.height = "auto";
        this.container.style.resize = "auto";

        this.createTitleBar(title);

        var div = document.createElement('div');
        div.setAttribute('id', 'horusSubPanel');
        this.container.appendChild(div);
    }
}