import { HorusExtensionPanel } from 'src/app/horusextensionpanel';
import { HmyPropsPanel } from './hmypropspanel';
import {
  KhepriExtensionPanel,
  main,
  setColor,
} from 'src/app/khepriextensionpanel';
import { AmonExtensionTree } from 'src/app/amonextensiontree';
import { AudExtensionTree } from './audextensiontree';
import { each } from 'jquery';
import { KhepriService } from './khepri.service';
import { HttpClient, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
// import { writeFile } from 'file-system';
declare var Autodesk: any;
declare var THREE: any;

export class HorusExtension extends Autodesk.Viewing.Extension {
  //Constructor

  constructor(viewer, options, allDataFromViewer) {
    //Variables locales del objeto.
    super(viewer, options);
    this.filtro = false;
    this.seleccionUnica = true;
    this.aislar = false;
    this.allDataFromViewer = allDataFromViewer;
    this.unnamedElements = [];
    this.viewer = viewer;
    this.getDataFromViewer(viewer);
    this.seccionesSeleccionadas = [];
    this.perfilesSeleccionados = [];
    this.categoriasSeleccionadas = [];
    this.colorKhepriArray = [];
    this.selectionMode = 'multiple';
    this.dbidArrayToHide = [];
    this.se;
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //                                                                                                                                                       //
  //                                                                                                                                                       //
  //                                                                                                                                                       //
  //                     Funciones de inicializacion de la barra de botones y funciones propias de la toolbar, sobre todo de la extension                  //
  //                                                                                                                                                       //
  //                                                                                                                                                       //
  //                                                                                                                                                       //
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  onToolbarCreated() {
    //inicializa la toolbar de los botones de las distinta extensiones
    this.toolbar = new Autodesk.Viewing.UI.ToolBar('hmyExtensionToolbar', {
      alignVertically: true,
    });
    //Si no existe el grupo, lo crea
    this.buttonGroupOnToolbar = this.viewer.toolbar.getControl(
      'hmyExtensionToolbar'
    );
    if (!this.buttonGroupOnToolbar) {
      this.buttonGroupOnToolbar = new Autodesk.Viewing.UI.ControlGroup(
        'hmyExtensionToolbar'
      );
      this.viewer.toolbar.addControl(this.buttonGroupOnToolbar);
    }
    //Estilos de los botones
    this.createButtons(this.viewer);
    this.toolbar.container.classList.add('atb-transparent');
    this.buttonGroupOnToolbar.container.classList.add('atb-transparent');
    this.toolbar.addControl(this.buttonGroupOnToolbar);
    this.viewer.container.appendChild(this.toolbar.container);
  }
  //Añade los botones
  createButtons(viewer) {
    // Añade el boton de Amon
    this.amon = new Autodesk.Viewing.UI.Button('amonButton');
    //Realiza las funciones que se ejecutan cuando clickas el boton
    this.amon.onClick = (ev) => {
      if (this.amonPanel == null) {
        this.amonPanel = new AmonExtensionTree(viewer, this.amon);
        //Añade los botones de Amon
        this.amonPanel.addListToTree([
          'Desactivar techo',
          // 'Filtrar por niveles',
          'Solo arquitectura',
          'Solo mobiliario',
          'Solo Luminarias',
        ]);
      }
      //Oculta o muestra el panel
      if (this.amonPanel.isShown()) {
        this.amonPanel.hide();
      } else {
        this.amonPanel.show();
      }
    };

    // Settings de Amon y clases visuales
    this.amon.setToolTip('Perfiles de Visualizacion');
    this.amon.icon.classList.add('fa');
    this.amon.icon.classList.add('fa-cog');
    this.amon.icon.classList.add('atb-horus-icon');
    this.amon.container.classList.add('atb-circle-border');
    this.buttonGroupOnToolbar.addControl(this.amon);
    // Añade el boton de Horus
    this.horus = new Autodesk.Viewing.UI.Button('horusButton');
    //Funcion que se ejecuta cuando clickas sobre el boton de Horus
    this.horus.onClick = (ev) => {
      if (this.horusPanel == null) {
        this.horusPanel = new HorusExtensionPanel(
          viewer,
          viewer.container,
          'horusPanel',
          'Visualización',
          null
        );
      }
      //Muestra o oculta el boton de horus conforme sea necesario
      this.horusPanel.setVisible(!this.horusPanel.isVisible());
      if (!this.horusPanel.isVisible()) {
        return;
      }
      //Genera khepri desde horus si no está iniciado para permitir el uso del mismo dentro de horus
      if (this.khepriPanel == null) {
        this.khepriPanel = new KhepriExtensionPanel(
          viewer,
          viewer.container,
          'khepriPanel',
          'Cambiar Color',
          null
        );
      }
      //Impide que khepri se abra cuando clickas sobre el mismo si ya está abierto horus
      this.khepriPanel.setVisible(this.khepriPanel.isVisible());
      if (this.khepriPanel.isVisible()) {
        return;
      }
      //Le damos tamaño al panel de horus
      document.getElementById('horusPanel').style.height = '600px';
      document.getElementById('horusPanel').style.width = '400px';
      //Inicializa la funcionalidad de horus una vez que ha cargado todo
      this.initHorusViewer();
    };
    //Settings de Horus de UI sobre todo
    this.horus.setToolTip('Visualización');
    this.horus.icon.classList.add('fa');
    this.horus.icon.classList.add('fa-bars');
    this.horus.icon.classList.add('atb-horus-icon');
    this.horus.container.classList.add('atb-circle-border');
    this.buttonGroupOnToolbar.addControl(this.horus);
    //Creacion de khepri
    this.khepri = new Autodesk.Viewing.UI.Button('khepriButton');
    //Funcion que se ejecuta cuando clickas sobre el boton de Khepri
    this.khepri.onClick = (ev) => {
      //Si no existe el panel de khepri lo crea
      if (this.khepriPanel == null) {
        this.khepriPanel = new KhepriExtensionPanel(
          viewer,
          viewer.container,
          'khepriPanel',
          'Cambiar Color',
          null
        );
      }

      //Mostrar u Ocultar el panel de khepri
      this.khepriPanel.setVisible(!this.khepriPanel.isVisible());
      //Oculta el panel y borra los colores del modelo o los vuelve a pintar
      if (!this.khepriPanel.isVisible()) {
        viewer.clearThemingColors();
        return;
      } else {
        setColor(viewer, this.colorKhepriArray);
      }
      //Genera el panel de horus si no existe.
      if (this.horusPanel == null) {
        this.horusPanel = new HorusExtensionPanel(
          viewer,
          viewer.container,
          'horusPanel',
          'Visualización',
          null
        );
      }
      //Hace que el panel de horus sea innaccesible si el panel de khepri existe.
      this.horusPanel.setVisible(this.horusPanel.isVisible());
      if (this.horusPanel.isVisible()) {
        return;
      }

      //Le da el tamaño a el panel de khepri
      document.getElementById('khepriPanel').style.height = '600px';
      document.getElementById('khepriPanel').style.width = '400px';
      //Ejecuta la funcion principal de khepri
      main(viewer, this.colorKhepriArray);
    };
    ////Configuracion de UI de khepri.
    this.khepri.setToolTip('Cambiar Color');
    this.khepri.icon.classList.add('fa');
    this.khepri.icon.classList.add('fa-paint-brush');
    this.khepri.icon.classList.add('atb-horus-icon');
    this.khepri.container.classList.add('atb-circle-border');
    this.buttonGroupOnToolbar.addControl(this.khepri);

    ////HMY PROPS
    this.hmyprops = new Autodesk.Viewing.UI.Button('hmyPropsButton');
    //Funcion que se ejecuta cuando clickas
    this.hmyprops.onClick = (ev) => {
      //Si no existe el panel lo crea
      if (this.HmyPropsPanel == null) {
        this.HmyPropsPanel = new HmyPropsPanel(
          viewer,
          viewer.container,
          'hmyPropsPanel',
          'Propiedades HMY',
          null
        );
      }

      //Mostrar u Ocultar el panel
      this.HmyPropsPanel.setVisible(!this.HmyPropsPanel.isVisible());
      //Oculta el panel y borra los colores del modelo o los vuelve a pintar
      if (!this.HmyPropsPanel.isVisible()) {
        return;
      } else {
        ('');
      }

      //Le da el tamaño a el panel
      document.getElementById('HmyPropsPanel').style.height = '600px';
      document.getElementById('HmyPropsPanel').style.width = '400px';
    };
    // this.hmyprops.setToolTip('Propiedades HMY');
    // this.hmyprops.icon.classList.add('fa');
    // this.hmyprops.icon.classList.add('fa-list-alt');
    // this.hmyprops.icon.classList.add('atb-horus-icon');
    // this.hmyprops.container.classList.add('atb-circle-border');
    // this.buttonGroupOnToolbar.addControl(this.hmyprops);

    //Check Missing HMY values button
    this.aud = new Autodesk.Viewing.UI.Button('audButton');
    //Funcion que recopila data y pinta elementos cuando clickas sobre el boton
    this.aud.onClick = (ev) => {
      if (this.audPanel == null) {
        this.audPanel = new AudExtensionTree(
          viewer,
          this.aud,
          this.unnamedElements
        );
        //Añade los botones de Amon
        this.audPanel.addListToTree([
          'Marcar elementos',
          'Exportar XLSX',
          'Exportar JSON',
          'Mandar informe (No implementado)',
        ]);
      }
      //Oculta o muestra el panel
      if (this.audPanel.isShown()) {
        this.audPanel.hide();
      } else {
        this.audPanel.show();
      }
    };
    this.aud.setToolTip('Comprobar parametros HMY');
    this.aud.icon.classList.add('fa');
    this.aud.icon.classList.add('fa-exclamation');
    this.aud.icon.classList.add('atb-horus-icon');
    this.aud.container.classList.add('atb-circle-border');
    this.buttonGroupOnToolbar.addControl(this.aud);

    this.notLoad();
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //                                                                                                                                                       //
  //                                                                                                                                                       //
  //                                                                                                                                                       //
  //                   Funciones de tratamiento de data: Data que se coge desde el visor y se almacena en variables globales                               //
  //                                                                                                                                                       //
  //                                                                                                                                                       //
  //                                                                                                                                                       //
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  //Trata los datos recibidos por el getBulkProperties
  formatDataFromBulkProperties(arrayPropertiesUnformatted) {
    var formattedPropertiesArray = [];
    var unnamedElements = new Set();
    // var conflictObj = [];

    //Recorremos el array sin formatear
    arrayPropertiesUnformatted.forEach(function (element) {
      //En caso de que HMYLineal no sea undefined lo tratamos y lo almacenamos.
      //----------------------------------------------------------------------------------------- 15/11/22 IF VALUE ""
      if (
        element.properties.find(
          (x) =>
            x.displayName == 'HMYLineal' &&
            x.displayValue != '' &&
            x.displayValue != undefined
        )
      ) {
        //Encontramos el HMYLineal del elemento

        var HMYLineal = element.properties.find(
          (x) => x?.displayName == 'HMYLineal'
        );
        // console.log(HMYLineal.displayValue);
        //Si está vació el HMYLineal le asignamos unnamedSection
        if (HMYLineal.displayValue === '') {
          // unnamedElements.add(element);
          HMYLineal.displayValue = 'unnamedSection';
        }

        //Encontramos el HmyProfile del elemento
        var HMYProfile = element.properties.find(
          (x) => x.displayName == 'HMYProfile'
        );
        // console.log(HMYProfile.displayValue);

        //Si está vacío lo nombramos como unnamedProfile
        if (HMYProfile.displayValue === '') {
          // unnamedElements.add(element);
          // HMYProfile.displayValue = 'unnamedProfile';
        }
        //Encontramos la descripcion del elemento
        var Description = element.properties.find(
          (x) => x.displayName == 'Description'
        );
        if (Description == null) {
          Description = element.properties.find(
            (x) => x.displayName == 'Descripción'
          );
        }
        //Si está vacía lo nombramos como unnamedDescription
        if (Description.displayValue === '') {
          Description.displayValue = 'unnamedDescription';
          unnamedElements.add(element);
        }
        //Este if inicializa el array de objetos en cada posicion en caso de que no existe. Si existe lo añade.
        if (!formattedPropertiesArray[HMYLineal.displayValue])
          formattedPropertiesArray[HMYLineal.displayValue] = [];
        if (
          !formattedPropertiesArray[HMYLineal.displayValue][
            HMYProfile.displayValue
          ]
        )
          formattedPropertiesArray[HMYLineal.displayValue][
            HMYProfile.displayValue
          ] = [];
        if (
          !formattedPropertiesArray[HMYLineal.displayValue][
            HMYProfile.displayValue
          ][Description.displayValue]
        )
          formattedPropertiesArray[HMYLineal.displayValue][
            HMYProfile.displayValue
          ][Description.displayValue] = [];

        formattedPropertiesArray[HMYLineal.displayValue][
          HMYProfile.displayValue
        ][Description.displayValue].push(element.dbId);
      }
    });
    //Asignamos a la variable del objeto el valor de las propiedades formateadas.

    // this.unnamedElements = unnamedElements;
    this.allDataFromViewer = formattedPropertiesArray;
  }

  formatAudData(emptyValArray) {
    var unnamedElements = new Set();

    emptyValArray.forEach(function (element) {
      if (element.properties.find((x) => x.displayName == 'HMYLineal')) {
        var HMYLineal = element.properties.find(
          (x) => x?.displayName == 'HMYLineal'
        );

        if (HMYLineal.displayValue === '') {
          unnamedElements.add(element);
        }

        //Encontramos el HmyProfile del elemento
        var HMYProfile = element.properties.find(
          (x) => x.displayName == 'HMYProfile'
        );

        if (HMYProfile.displayValue === '') {
          unnamedElements.add(element);
        }

        var Description = element.properties.find(
          (x) => x.displayName == 'Description'
        );
        if (Description == null) {
          Description = element.properties.find(
            (x) => x.displayName == 'Descripción'
          );
        }
        if (Description.displayValue === '') {
          unnamedElements.add(element);
        }
      }
    });
    this.unnamedElements = unnamedElements;
  }

  //Obtenemos la data desde el visor
  getDataFromViewer(viewer) {
    viewer.model.getBulkProperties(
      null,
      ['name', 'externalId', 'HMYLineal', 'HMYProfile', 'Description'],
      // ['HMYLineal', 'HMYProfile', 'Description', 'Code', 'Anfitrión'],
      // (res) => this.formatDataFromBulkProperties(res)
      (res) => {
        let filteredArr = [];
        let unnamedEl = [];
        res.forEach((element) => {
          let propL = element.properties.length;
          let propCounter = 0;
          element.properties.forEach((y) => {
            if (y.displayValue != '') {
              propCounter++;
            }
          });

          if (propL === propCounter) {
            // console.log(element);
            filteredArr.push(element);
          }
        });

        this.formatDataFromBulkProperties(filteredArr);
        this.formatAudData(res);
      }
    );
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //                                                                                                                                                       //
  //                                                                                                                                                       //
  //                                                                                                                                                       //
  //                               Funciones de UI: Crear botones, crear los divs y todo lo visual de dentro                                               //
  //                                                                                                                                                       //
  //                                                                                                                                                       //
  //                                                                                                                                                       //
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  cerrarHorusViewer() {
    this.horusPanel.setVisible(!this.horusPanel.isVisible());
    if (!this.horusPanel.isVisible()) {
      return;
    }
  }

  //Crea los divs para añadirlos al docking panel
  //ALEJANDRO AQUI HABRA QUE HACER MOVIDAS.
  initializeHorusViewer(div, main, innerdiv) {
    //Reiniciamos los arrays de data a la vez que reiniciamos el
    this.seccionesSeleccionadas = [];
    this.perfilesSeleccionados = [];
    this.categoriasSeleccionadas = [];
    //Reiniciamos el div a vacio y le asignamos estilos basicos
    div.innerHTML = '';

    div.style.height = 'calc(100% - 100px)';
    main.setAttribute('id', 'mainPanelHorus');
    main.style.height = 'calc(100%)';
    main.classList.add('overflow-auto');
    //Navbar de seccion ( el que pone la seccion seleccionada)
    var navbar = document.createElement('div');
    navbar.setAttribute('id', 'horusSectionNavbar');
    navbar.classList.add('atb-teal');
    navbar.classList.add('py-2');
    var innerNavbarDiv = document.createElement('div');
    innerNavbarDiv.classList.add('atb-container');
    innerNavbarDiv.innerHTML = `<span class="atb-h4">Secciones</span>`;
    var closeButton = document.createElement('i');
    navbar.appendChild(innerNavbarDiv);

    main.appendChild(navbar);
    //Navbar para perfiles
    var navbarProfile = document.createElement('div');
    navbarProfile.setAttribute('id', 'horusProfileNavbar');
    navbarProfile.style.display = 'none';
    navbarProfile.classList.add('atb-teal');
    navbarProfile.classList.add('py-2');
    navbarProfile.onclick = () => this.initHorusViewer();
    navbarProfile.innerHTML = `<div class="atb-h4 text-center">Perfiles <a class="float-end me-4"><i class="atb-teal fa fa-arrow-up" ></i></a></div>`;
    main.appendChild(navbarProfile);
    //Navbar de producto (el que tiene tanto el perfil como la descripcion)
    var navbarProduct = document.createElement('div');
    navbarProduct.setAttribute('id', 'horusProductNavbar');
    navbarProduct.style.display = 'none';
    navbarProduct.classList.add('atb-teal');
    navbarProduct.onclick = () =>
      this.openProfiles(this.seccionesSeleccionadas);
    navbarProduct.innerHTML = `<div class="atb-h4 text-center">Productos <a class="float-end me-4"><i class="atb-teal fa fa-arrow-up" ></i></a></div>`;
    main.appendChild(navbarProduct);
    //Añadimos estilos y atributos a innerdiv
    innerdiv.setAttribute('id', 'listaInterna');
    innerdiv.classList.add('overflow-auto');
    innerdiv.style.height = 'calc(100% - 98px)';
  }
  //Ordena los divs y los coloca de forma correcta sobre el docking panel
  sortAndDrawItems(innerdiv, main) {
    Object.keys(this.allDataFromViewer)
      .sort()
      .forEach((keysFromData) => {
        let dat = this.allDataFromViewer[keysFromData];
        dat.forEach((e) => {
          // console.log(e);
        });
        var elemento = document.createElement('div');
        elemento.classList.add('atb-horus-element');
        elemento.onclick = () => this.selectSeccion(keysFromData);
        elemento.innerHTML = ` <div id="${keysFromData}-badge"class=" mx-1 badge badge-pill badge-primary atb-reversed-teal"> ${keysFromData} </div> <span id="${keysFromData}-open" class>Sección ${keysFromData}</span><div class="float-end">x${
          Object.keys(this.allDataFromViewer[keysFromData]).length
        }</div>`;
        innerdiv.appendChild(elemento);
      });
    main.appendChild(innerdiv);
  }
  //Inicializa la barra de controles de Horus
  InitHorusControls(main) {
    var controlsDiv = document.createElement('div');
    controlsDiv.style.zIndex = '99';
    controlsDiv.classList.add('atb-confirmbox', 'form-check', 'form-switch');
    controlsDiv.classList.add('border-top', 'overflow-auto');
    controlsDiv.classList.add('position-absolute', 'bottom-0');
    controlsDiv.innerHTML = ` `;
    //Creacion de los elementos HTML y añadir cada una de las clases.
    var parentChecboxSeleccionUnica = document.createElement('div');
    parentChecboxSeleccionUnica.innerHTML = 'Seleccion única' + ' ';
    parentChecboxSeleccionUnica.classList.add('my-2', 'ml-2');
    var parentCheckboxSeleccionPorFiltro = document.createElement('div');
    parentCheckboxSeleccionPorFiltro.innerHTML = 'Seleccion por filtro' + ' ';
    parentCheckboxSeleccionPorFiltro.classList.add('my-2', 'ml-2');
    var parentCheckboxAislarElementos = document.createElement('div');
    parentCheckboxAislarElementos.innerHTML = 'Aislar elementos' + ' ';
    parentCheckboxAislarElementos.classList.add('my-2', 'ml-2');
    var checkboxseleccionporfiltro = document.createElement('input');
    checkboxseleccionporfiltro.id = 'filtrado';
    //el onclick de la checkbox llama a las funciones de ejecucion
    checkboxseleccionporfiltro.onclick = () => this.selectItemsInViewer();
    checkboxseleccionporfiltro.type = 'checkbox';
    checkboxseleccionporfiltro.checked = this.filtro;
    checkboxseleccionporfiltro.classList.add('form-check-input');
    var checkboxseleccionunica = document.createElement('input');
    checkboxseleccionunica.id = 'unico';
    //el onclick de la checkbox llama a las funciones de ejecucion
    checkboxseleccionunica.onclick = () => this.selectItemsInViewer();
    checkboxseleccionunica.type = 'checkbox';
    checkboxseleccionunica.checked = this.seleccionUnica;
    checkboxseleccionunica.classList.add('form-check-input');
    //Añadimos los elementos a sus respectivos padres.
    parentCheckboxSeleccionPorFiltro.appendChild(checkboxseleccionporfiltro);
    controlsDiv.appendChild(parentCheckboxSeleccionPorFiltro);
    parentChecboxSeleccionUnica.appendChild(checkboxseleccionunica);
    controlsDiv.appendChild(parentChecboxSeleccionUnica);
    var buttonDeEntradaEnSubconjunto = document.createElement('button');
    buttonDeEntradaEnSubconjunto.id = 'btnViewerSelect';
    buttonDeEntradaEnSubconjunto.classList.add('atb-button-confirm', 'btn');
    buttonDeEntradaEnSubconjunto.innerHTML = ` <i class="fa fa-check"></i>`;

    buttonDeEntradaEnSubconjunto.onclick = () => {
      this.viewerSelect();
    };
    var checkboxaislarelementos = document.createElement('input');
    checkboxaislarelementos.id = 'aislado';
    checkboxaislarelementos.type = 'checkbox';
    checkboxaislarelementos.checked = this.aislar;
    checkboxaislarelementos.classList.add('form-check-input');
    checkboxaislarelementos.onclick = () => this.selectItemsInViewer();
    parentCheckboxAislarElementos.appendChild(checkboxaislarelementos);
    controlsDiv.appendChild(parentCheckboxAislarElementos);
    controlsDiv.appendChild(buttonDeEntradaEnSubconjunto);
    main.appendChild(controlsDiv);
    main.appendChild(this.allDataFromViewerdoPerfiles);
  }
  //Funcion que inicializa el visor a nivel visual.
  initHorusViewer() {
    //Iniciamos en modo secciones.
    this.mode = 'Secciones';
    var horusPanelContainer = document.getElementById('horusSubPanel');
    var mainComponentHorusPanel = document.createElement('div');
    var subcomponentHorusPanel = document.createElement('div');
    this.initializeHorusViewer(
      horusPanelContainer,
      mainComponentHorusPanel,
      subcomponentHorusPanel
    );
    this.sortAndDrawItems(subcomponentHorusPanel, mainComponentHorusPanel);
    this.allDataFromViewerdoPerfiles = document.createElement('div');
    this.allDataFromViewerdoPerfiles.setAttribute('id', 'profilesList');
    this.allDataFromViewerdoPerfiles.style.display = 'none';
    this.allDataFromViewerdoPerfiles.classList.add('overflow-auto');
    this.allDataFromViewerdoPerfiles.style.height = 'calc(100% - 145px)';
    horusPanelContainer.appendChild(mainComponentHorusPanel);
    this.InitHorusControls(mainComponentHorusPanel);
    this.selectItemsInViewer();
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //                                                                                                                                                       //
  //                                                                                                                                                       //
  //                                                                                                                                                       //
  //                                Funciones de navegacion: Funciones que permiten la navegacion en el panel de Horus                                     //
  //                                                                                                                                                       //
  //                                                                                                                                                       //
  //                                                                                                                                                       //
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  openDescription(perfiles) {
    this.mode = 'Perfiles';
    let boton = <HTMLButtonElement>document.getElementById('btnViewerSelect');
    boton.onclick = () => this.cerrarHorusViewer();
    this.perfilesSeleccionados = [];
    var listaPerfiles = [];
    perfiles.forEach((perfil) => {
      this.perfilesSeleccionados.push(perfil);
    });
    let secciones = this.seccionesSeleccionadas;
    var demolist = document.getElementById('listaInterna');
    let navbarProfile = document.getElementById('horusProfileNavbar');
    navbarProfile.classList.remove('atb-teal');
    navbarProfile.classList.add('atb-verygreyed');
    navbarProfile.classList.add('clearfix');
    this.CreateProfilesBar(navbarProfile, this.perfilesSeleccionados);
    let navbarProduct = document.getElementById('horusProductNavbar');
    navbarProduct.style.display = 'inline-block';
    navbarProduct.classList.add('w-100');
    navbarProduct.classList.add('py-2');
    navbarProduct.innerHTML = `<div class="atb-h4 text-center">Accesorios <a class="float-end me-3"><i class="atb-teal fa fa-arrow-up" aria-hidden="true"></i></a></div>`;
    demolist.style.display = 'none';
    let allDataFromViewerPerfiles = document.getElementById('profilesList');
    allDataFromViewerPerfiles.style.display = 'block';
    allDataFromViewerPerfiles.innerHTML = '';
    this.allDataFromViewerdoPerfiles.style.height = 'calc(100% - 145px)';
    let lista = this.GroupSections(this.seccionesSeleccionadas);
    let SeccionesActuales = [];
    perfiles.forEach((perfil) => {
      lista.forEach((val, key) => {
        if (perfil == key) {
          val.forEach((seccion) => {
            SeccionesActuales.indexOf(seccion) === -1
              ? SeccionesActuales.push(seccion)
              : console.log('');
          });
        }
      });
    });
    this.CreateSection(
      document.getElementById('horusSectionNavbar'),
      SeccionesActuales
    );

    listaPerfiles = this.MapaPerfiles(secciones, perfiles, listaPerfiles);
    listaPerfiles.forEach((valor, clave) => {
      // ---------------------------------------------------------------------- accesorio not "" 15/11/22
      if (clave.toString() != '') {
        var elemento = document.createElement('div');
        elemento.classList.add('atb-horus-element');
        elemento.onclick = () => this.selectCategories(clave);
        this.createAccesoriosHTML(elemento, valor, clave);
        allDataFromViewerPerfiles.appendChild(elemento);
      }
    });
  }

  CreateProfilesBar(navbar, perfiles) {
    var texto = ` <a class="float-end atb-verygreyed pe-3 atb-h4"><i class="fa fa-arrow-up" ></i></a><div class="atb-container clearfix "> <span class="atb-h4 text-center  " > Perfiles </span>   `;
    perfiles.forEach((perfil) => {
      var nuevo =
        `<span class=" mx-1 badge atb-h5 badge-pill badge-primary atb-reversed-teal-selected ">` +
        perfil +
        `</span>`;
      texto += nuevo;
    });
    navbar.innerHTML = texto + '</div>';
  }
  MapaPerfiles(secciones, perfiles, lista) {
    lista = new Map();
    secciones.forEach((seccion) => {
      Object.keys(this.allDataFromViewer[seccion]).forEach((perfil) => {
        if (perfiles.includes(perfil)) {
          Object.keys(this.allDataFromViewer[seccion][perfil]).forEach(
            (producto) => {
              lista.set(producto, [perfil]);
            }
          );
        }
      });
    });
    secciones.forEach((seccion) => {
      Object.keys(this.allDataFromViewer[seccion]).forEach((perfil) => {
        if (perfiles.includes(perfil)) {
          Object.keys(this.allDataFromViewer[seccion][perfil]).forEach(
            (producto) => {
              lista.forEach(function (valor, clave) {
                if (clave == producto && !valor.includes(perfil)) {
                  valor.push(perfil);
                  valor.sort();
                }
              });
            }
          );
        }
      });
    });
    return lista;
  }

  //funcion que abre la ventana de seleccion de perfiles.
  openProfiles(secciones) {
    this.perfilesSeleccionados = [];
    this.categoriasSeleccionadas = [];
    this.mode = 'Perfiles';
    this.seccionesSeleccionadas = [];
    var lista;
    //bucle rellenar el array con cada uno de los valores(mirar de hacer mejor)
    secciones.forEach((seccion) => {
      this.seccionesSeleccionadas.push(seccion);
    });
    this.perfilesSeleccionados = [];
    var demolist = document.getElementById('listaInterna');
    let navbar = document.getElementById('horusSectionNavbar');
    let accesoriosNavbar = document.getElementById('horusProductNavbar');
    accesoriosNavbar.style.display = 'none';
    let navbarProfiles = document.getElementById('horusProfileNavbar');
    navbarProfiles.classList.remove('atb-verygreyed');
    navbarProfiles.classList.add('atb-teal');
    let boton = <HTMLInputElement>document.getElementById('btnViewerSelect');
    boton.disabled = false;
    boton.onclick = () => this.openDescription(this.perfilesSeleccionados);
    navbarProfiles.style.display = 'inline-block';
    navbarProfiles.classList.add('w-100');
    navbarProfiles.classList.add('py-2');
    navbarProfiles.innerHTML = `<div class="atb-h4 text-center">Perfiles <a class="float-end me-3"><i class="atb-teal fa fa-arrow-up" ></i></a></div>`;

    navbar.classList.remove('atb-teal');
    navbar.classList.add('atb-greyed');

    this.CreateSection(navbar, secciones);
    demolist.style.display = 'none';
    let allDataFromViewerPerfiles = document.getElementById('profilesList');
    allDataFromViewerPerfiles.innerHTML = '';
    allDataFromViewerPerfiles.style.display = 'block';
    this.allDataFromViewerdoPerfiles.style.height = 'calc(100% - 145px)';
    lista = this.GroupSections(secciones);

    lista.forEach((valor, clave) => {
      //------------------------------------------------------------------ if section "" 15/11/22
      if (clave != '') {
        var elemento = document.createElement('div');
        elemento.classList.add('atb-horus-element');
        elemento.onclick = () => this.selectProfiles(clave);
        this.createPerfilesHtml(elemento, valor, clave);
        /*elemento.innerHTML = ` 
        <div id="${valor}-badge" class=" mx-1 float-left badge badge-secondary atb-section" style="z-index:10"> ${valor} </div>
        <div id="${clave}-badge" class=" mx-1 float-left badge badge-pill badge-primary atb-reversed-teal" style="z-index:10"> ${clave} </div>
         <span  id="${clave}-open">Perfil ${clave}</span>`;*/
        allDataFromViewerPerfiles.appendChild(elemento);
      }
    });
  }
  //generar el html de las secciones y los perfiles
  createPerfilesHtml(elementoHtml, secciones, perfil) {
    var texto = `<div id="${secciones}" class="">`;
    let total = 0;
    secciones.forEach((element) => {
      // element.forEach((e) => {
      //   console.log(e);
      // });
      let prof = this.allDataFromViewer[element][perfil];
      // console.log(prof);
      total += Object.keys(this.allDataFromViewer[element][perfil]).length;
      var nuevo = `<span id="${element}-badge" class=" mx-1 mb-2 mt-1  badge badge-secondary atb-section atb-reversed-teal-selected2" style="z-index:8"> ${element} </span>`;
      texto += nuevo;
    });
    elementoHtml.innerHTML =
      texto +
      `</div>` +
      `<div id="${perfil}-badge" class=" mx-1 float-start badge badge-pill badge-primary atb-reversed-teal hijo" style="z-index:8">  ${perfil} </div>
     <span  id="${perfil}-open">Perfil ${perfil}</span>  <span class="float-end">x${total}</span>`;
  }
  createAccesoriosHTML(elemento, valor, clave) {
    let profilesList = document.getElementById('profilesList');
    profilesList.style.height = 'calc(100% - 194px)';
    var texto = `<div id="${valor}" >`;
    valor.forEach((element) => {
      var nuevo = `<span id="${element}-badge" class="  mx-1 mb-2 mt-1 badge badge-secondary atb-section atb-reversed-teal-selected2" style="z-index:8"> ${element} </span>`;
      texto += nuevo;
    });
    elemento.innerHTML =
      texto +
      `</div>` +
      `<div id="${clave}-badge" class=" p-2 mx-2 float-start badge badge-pill badge-primary atb-reversed-teal hijo" style="z-index:8">  </div><div id="${clave}-badge" class=" mx-1 hijo" style="z-index:8"> ${clave} </div>`;
  }

  //crea la navbar de secciones con las secciones que les pasamos debajo del titulo
  CreateSection(navbar, secciones) {
    var texto = `<div class="atb-container"> <span class="atb-h4 text-center" > Secciones </span> `;
    secciones.forEach((seccion) => {
      var nuevo =
        `<span class=" mx-1 badge atb-h5 badge-pill badge-primary atb-reversed-teal-selected ">` +
        seccion +
        `</span>`;
      texto += nuevo;
    });
    navbar.innerHTML = texto + `</div>`;
  }

  //generar mapa con clave con las propiedades y el valor un array con las secciones que tengan esas propiedades
  GroupSections(secciones) {
    let lista = new Map();
    secciones.forEach((seccion) => {
      Object.keys(this.allDataFromViewer[seccion]).forEach((p) => {
        lista.set(p, [seccion]);
      });
    });
    secciones.forEach((seccion) => {
      Object.keys(this.allDataFromViewer[seccion]).forEach((p) => {
        lista.forEach(function (valor, clave) {
          if (clave == p && !valor.includes(seccion)) {
            valor.push(seccion);
            valor.sort();
          }
        });
      });
    });
    return lista;
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //                                                                                                                                                       //
  //                                                                                                                                                       //
  //                                                                                                                                                       //
  //                    Funciones de seleccion: Funciones que permiten la seleccion de distintos elementos en el panel de Horus                            //
  //                                                                                                                                                       //
  //                                                                                                                                                       //
  //                                                                                                                                                       //
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // ???????????????
  viewerSelect() {
    this.openProfiles(this.seccionesSeleccionadas);
  }
  //Seleccionamos una seccion y lo añadimos al array de secciones
  selectSeccion(seccion) {
    if (this.selectionMode == 'unico') {
      let selected = this.seccionesSeleccionadas.find((x) => x === seccion);
      this.seccionesSeleccionadas = [];
      var todos = document
        .getElementById('listaInterna')
        .querySelectorAll('.atb-reversed-teal-selected');

      todos.forEach((div) => {
        div.classList.remove('atb-reversed-teal-selected');
        div.classList.add('atb-reversed-teal');
      });
      if (selected === undefined) {
        this.seccionesSeleccionadas.push(seccion);
        let actual = document.getElementById(seccion + '-badge');
        actual.classList.remove('atb-reversed-teal');
        actual.classList.add('atb-reversed-teal-selected');
      }
    } else {
      let actual = document.getElementById(seccion + '-badge');
      let selected = this.seccionesSeleccionadas.find((x) => x === seccion);
      if (selected === undefined) {
        this.seccionesSeleccionadas.push(seccion);
        actual.classList.remove('atb-reversed-teal');
        actual.classList.add('atb-reversed-teal-selected');
      } else {
        let index = this.seccionesSeleccionadas.indexOf(seccion);
        if (index > -1) {
          this.seccionesSeleccionadas.splice(index, 1);
        }
        actual.classList.remove('atb-reversed-teal-selected');
        actual.classList.add('atb-reversed-teal');
      }
    }
    this.selectItemsInViewer();
  }
  //Seleccionamos un perfil y lo añadimos al array de perfiles
  selectProfiles(profile) {
    if (this.selectionMode == 'unico') {
      let selected = this.perfilesSeleccionados.find((x) => x === profile);
      this.perfilesSeleccionados = [];
      var todos = document
        .getElementById('profilesList')
        .querySelectorAll('.atb-reversed-teal-selected');
      todos.forEach((div) => {
        div.classList.remove('atb-reversed-teal-selected');
        div.classList.add('atb-reversed-teal');
      });
      if (selected === undefined) {
        this.perfilesSeleccionados.push(profile);
        let actual = document.getElementById(profile + '-badge');
        actual.classList.remove('atb-reversed-teal');
        actual.classList.add('atb-reversed-teal-selected');
      }
    } else {
      let actual = document.getElementById(profile + '-badge');
      let selected = this.perfilesSeleccionados.find((x) => x === profile);
      if (selected === undefined) {
        this.perfilesSeleccionados.push(profile);
        actual.classList.remove('atb-reversed-teal');
        actual.classList.add('atb-reversed-teal-selected');
      } else {
        let index = this.perfilesSeleccionados.indexOf(profile);
        if (index > -1) {
          this.perfilesSeleccionados.splice(index, 1);
        }
        actual.classList.remove('atb-reversed-teal-selected');
        actual.classList.add('atb-reversed-teal');
      }
    }
    this.selectItemsInViewer();
  }
  //Seleccionamos una categoria y la añadimos al array de seleccion
  selectCategories(category) {
    if (this.selectionMode == 'unico') {
      let selected = this.categoriasSeleccionadas.find((x) => x === category);
      this.categoriasSeleccionadas = [];
      var todos = document
        .getElementById('profilesList')
        .querySelectorAll('.atb-reversed-teal-selected');

      todos.forEach((div) => {
        div.classList.remove('atb-reversed-teal-selected');
        div.classList.add('atb-reversed-teal');
      });
      if (selected === undefined) {
        this.categoriasSeleccionadas.push(category);
        let actual = document.getElementById(category + '-badge');
        actual.classList.remove('atb-reversed-teal');
        actual.classList.add('atb-reversed-teal-selected');
      }
    } else {
      let actual = document.getElementById(category + '-badge');

      let selected = this.categoriasSeleccionadas.find((x) => x === category);
      if (selected === undefined) {
        this.categoriasSeleccionadas.push(category);
        actual.classList.remove('atb-reversed-teal');
        actual.classList.add('atb-reversed-teal-selected');
      } else {
        let index = this.categoriasSeleccionadas.indexOf(category);
        if (index > -1) {
          this.categoriasSeleccionadas.splice(index, 1);
        }
        actual.classList.remove('atb-reversed-teal-selected');
        actual.classList.add('atb-reversed-teal');
      }
    }
    this.selectItemsInViewer();
  }
  //Seleccion de items en el visor.
  selectItemsInViewer() {
    if ((<HTMLInputElement>document.getElementById('unico')).checked) {
      this.seleccionUnica = true;
      this.selectionMode = 'unico';
    } else {
      this.seleccionUnica = false;
      this.selectionMode = 'multiple';
    }
    this.viewer.clearThemingColors();
    this.viewer.clearSelection();
    let selection = [];
    if (
      this.perfilesSeleccionados.length === 0 &&
      this.categoriasSeleccionadas.length === 0
    ) {
      this.seccionesSeleccionadas.forEach((seccion) => {
        Object.keys(this.allDataFromViewer[seccion]).forEach((perfil) => {
          Object.keys(this.allDataFromViewer[seccion][perfil]).forEach(
            (categoria) => {
              this.allDataFromViewer[seccion][perfil][categoria].forEach(
                (dbid) => {
                  selection.push(dbid);
                }
              );
            }
          );
        });
      });
    } else if (this.categoriasSeleccionadas.length === 0) {
      this.seccionesSeleccionadas.forEach((seccion) => {
        this.perfilesSeleccionados.forEach((perfil) => {
          try {
            Object.keys(this.allDataFromViewer[seccion][perfil]).forEach(
              (categoria) => {
                try {
                  this.allDataFromViewer[seccion][perfil][categoria].forEach(
                    (dbid) => {
                      selection.push(dbid);
                    }
                  );
                } catch (e) {}
              }
            );
          } catch (e) {}
        });
      });
    } else {
      this.seccionesSeleccionadas.forEach((seccion) => {
        this.perfilesSeleccionados.forEach((perfil) => {
          this.categoriasSeleccionadas.forEach((categoria) => {
            try {
              this.allDataFromViewer[seccion][perfil][categoria].forEach(
                (dbid) => {
                  selection.push(dbid);
                }
              );
            } catch (e) {}
          });
        });
      });
    }
    //Controlamos las checkboxes
    if ((<HTMLInputElement>document.getElementById('filtrado')).checked) {
      this.filtro = true;
      this.viewer.select(selection);
    } else {
      this.filtro = false;
      selection.forEach((e) => {
        let color = new THREE.Vector4(0, 0.5, 0.73, 1);
        this.viewer.setThemingColor(e, color, this.viewer.model, true);
      });
    }
    this.viewer.fitToView(selection);
    //Controlamos las checkboxes
    if ((<HTMLInputElement>document.getElementById('aislado')).checked) {
      this.aislar = true;
      this.viewer.isolate(selection);
    } else {
      this.aislar = false;
      this.mostarTodo();
    }
  }
  //Muestra todo
  mostarTodo() {
    this.viewer.showAll();
    if (this.amonPanel != undefined) {
      this.viewer.hide(this.amonPanel.arrayDbids);
    }
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //                                                                                                                                                       //
  //                                                                                                                                                       //
  //                                                                                                                                                       //
  //                                                               Reverse selector and HMY props                                                          //
  //                                                                                                                                                       //
  //                                                                                                                                                       //
  //                                                                                                                                                       //
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  //binding
  notLoad() {
    this.onSelectionBinded = this.onSelectionEvent.bind(this);
    this.viewer.addEventListener(
      Autodesk.Viewing.SELECTION_CHANGED_EVENT,
      this.onSelectionBinded
    );
  }

  // viewer selection
  onSelectionEvent(event) {
    var singleSelection = this.viewer.getSelection();

    if (
      singleSelection != null &&
      singleSelection != undefined &&
      singleSelection != ''
    ) {
      //load props panel
      if (this.HmyPropsPanel == null) {
        this.HmyPropsPanel = new HmyPropsPanel(
          this.viewer,
          this.viewer.container,
          'hmyPropsPanel',
          'Propiedades HMY',
          null
        );
      }
      // console.log(singleSelection);
      // this.viewer.model.getProperties(singleSelection[0], (res) => {
      //   console.log(res);
      // });

      // get props for the selected element
      this.viewer.model.getBulkProperties(
        singleSelection,
        [
          'Code',
          'Width',
          'Height',
          'Depth',
          'Descripción',
          'Description',
          'name',
          'HMYProfile',
          'HMYLineal',
        ],
        (res) => {
          // console.log(res[0]);

          //write props label & value on the panel
          // Title - name
          document.getElementById('propsNavbar').innerText = res[0].name;
          //table
          document.getElementById('props1Title').innerText =
            res[0].properties[0]?.displayName;
          document.getElementById('props1Value').innerText =
            res[0].properties[0]?.displayValue;
          document.getElementById('props2Title').innerText =
            res[0].properties[1]?.displayName;
          document.getElementById('props2Value').innerText =
            res[0].properties[1]?.displayValue;
          document.getElementById('props3Title').innerText =
            res[0].properties[2]?.displayName;
          document.getElementById('props3Value').innerText =
            res[0].properties[2]?.displayValue;
          document.getElementById('props4Title').innerText =
            res[0].properties[3]?.displayName;
          document.getElementById('props4Value').innerText =
            res[0].properties[3]?.displayValue;
          document.getElementById('props5Title').innerText =
            res[0].properties[4]?.displayName;
          document.getElementById('props5Value').innerText =
            res[0].properties[4]?.displayValue;
          document.getElementById('props6Title').innerText =
            res[0].properties[5]?.displayName;
          document.getElementById('props6Value').innerText =
            res[0].properties[5]?.displayValue;
          document.getElementById('props6Title').innerText =
            res[0].properties[5]?.displayName;
          document.getElementById('props6Value').innerText =
            res[0].properties[5]?.displayValue;
        }
      );
    }
    //Set dimensions & visibility
    document.getElementById('hmyPropsPanel').style.height = '330px';
    document.getElementById('hmyPropsPanel').style.width = '350px';
    document.getElementById('hmyPropsPanel').style.removeProperty('display');
  }

  //
}
