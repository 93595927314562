import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';

@Injectable({
  providedIn: 'root'
})
export class MsalauthService {

  constructor(private msalService:MsalService,private router:Router) { }


  isLoggedIn():boolean{
    return  this.msalService.instance.getAllAccounts().length > 0;
    
  }
  
  logout(){
    this.msalService.logout({postLogoutRedirectUri:"/"});
  }
}
