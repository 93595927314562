declare var Autodesk: any;
declare var THREE: any;
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
} from 'chart.js';
import { from } from 'rxjs';
import { ThotExtensionPanel } from './thotextensionpanel';
import { element } from 'protractor';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { formattedError } from '@angular/compiler';
export class ThotExtension extends Autodesk.Viewing.Extension {
  constructor(viewer, options) {
    super(viewer, options);
    this.viewer = viewer;
    this._group = null;
    this.allDataFromViewer = [];
    this.getDataFromViewer(this.viewer);
  }

  load() {
    return true;
  }

  unload() {
    return true;
  }

  onToolbarCreated() {
    Chart.register(
      ArcElement,
      LineElement,
      BarElement,
      PointElement,
      BarController,
      BubbleController,
      DoughnutController,
      LineController,
      PieController,
      PolarAreaController,
      RadarController,
      ScatterController,
      CategoryScale,
      LinearScale,
      LogarithmicScale,
      RadialLinearScale,
      TimeScale,
      TimeSeriesScale,
      Decimation,
      Filler,
      Legend,
      Title,
      Tooltip
    );
    this._group = this.viewer.toolbar.getControl('hmyExtensionToolbar');
    if (!this._group) {
      console.error('No existe la Toolbar');
    }

    // Add a new button to the toolbar group

    this._button = new Autodesk.Viewing.UI.Button('thotButton');
    this._button.onClick = (ev) => {
      if (this.thotPanel == null) {
        this.thotPanel = new ThotExtensionPanel(
          this.viewer,
          this.viewer.container,
          'thotPanel',
          'Inventario',
          null
        );
      }
      //Muestra o oculta el boton de thot conforme sea necesario
      this.thotPanel.setVisible(!this.thotPanel.isVisible());
      if (!this.thotPanel.isVisible()) {
        return;
      }
      this.init();
    };
    this._button.setToolTip('Inventario');
    this._button.icon.classList.add('fa');
    this._button.icon.classList.add('fa-chart-bar');
    this._button.icon.classList.add('atb-horus-icon');
    this._button.container.classList.add('atb-circle-border');
    this._group.addControl(this._button);
  }

  componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? '0' + hex : hex;
  }

  rgbToHex(r, g, b) {
    return (
      '#' +
      this.componentToHex(r) +
      this.componentToHex(g) +
      this.componentToHex(b)
    );
  }
  createHtmlElement(type, id, classList, width, height, innerHTML) {
    var element = document.createElement(type);
    element.id = id;
    classList.forEach((Value) => {
      element.classList.add(Value);
    });
    element.style.width = width;
    element.style.height = height;
    element.innerHTML = innerHTML;
    return element;
  }
  init() {
    var accordionParent = this.createHtmlElement(
      'div',
      'accordionParent',
      ['d-flex', 'justify-content-end', 'flex-column', 'align-items-end'],
      null,
      '',
      `
    <div id="accordionComponent" style="z-index:9999;" class="accordion accordion-flush border-top">
      <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                        aria-expanded="false" aria-controls="collapseOne">
                        Width
                  </button>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse " aria-labelledby="headingOne"
                  data-bs-parent="#accordionComponent">
                  <div id="width-accordion" class="accordion-body p-0 bg-white" style="min-height:135px;">
                  </div>
            </div>
      </div>
      <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        Height
                  </button>
            </h2>
            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                  data-bs-parent="#accordionComponent">
                  <div id="height-accordion" class="accordion-body p-0 bg-white" style="min-height:135px;">
                  </div>
            </div>
      </div>
      <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        Depth
                  </button>
            </h2>
            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                  data-bs-parent="#accordionComponent">
                  <div id="depth-accordion" class="accordion-body p-0 bg-white" style="height:135px;">
                  </div>
            </div>
      </div>
</div>`
    );
    var thotSubpanel = document.getElementById('thotSubPanel');
    thotSubpanel.innerHTML = '';
    thotSubpanel.style.height = 'calc(100% - 70px)';
    thotSubpanel.style.width = '100%';
    var thotNavbar = this.createHtmlElement(
      'div',
      'thotNavbar',
      ['align-items-center', 'atb-teal', 'py-2'],
      '100%',
      'null',
      `<div class="atb-h4 text-center">Inventario</div>`
    );
    var thotSearchBar = this.createHtmlElement(
      'div',
      'thotSearchBar',
      ['d-flex', 'absolute'],
      null,
      '',
      '<div class="col-1 m-0 p-0 d-flex justify-content-center align-items-center text-center atb-buscador"><i class="fa fa-search atb-h4  text-center" style = "vertical-align: middle;"></i></div><div class="m-0 p-0  atb-buscador col-10"><input  id="thotSearch" type="text" class=" py-2 px-0  form-control atb-buscador" style="background-color:#c4c9cc" placeholder="Buscar por Descripción"."></div><div class="col-1 m-0 p-0 d-flex justify-content-center align-items-center text-center atb-buscador"><i class="fa fa-close atb-h4  text-center" style ="vertical-align: middle, horizontal-align: end" onclick="document.getElementById(\'thotSearch\').value=\'\'"/></div>'
    );

    var selectElement = this.createHtmlElement(
      'select',
      'selectElement',
      ['atb-form-select', 'mw-100', 'px-0'],
      null,
      null,
      '<option value="" disabled selected>Selecciona un accesorio </option>'
    );
    var chart = null;
    var widthChart = null;
    var depthChart = null;
    var heightChart = null;
    selectElement.addEventListener('change', async () => {
      var linealData = [];
      var linealLabels = [];
      var colores = [];
      this.getLinealContainingAccesory(
        (<HTMLSelectElement>document.getElementById('selectElement')).value
      ).forEach((val) => {
        linealData.push(val[1]);
        linealLabels.push(val[0]);
        colores.push(val[2]);
      });
      if (chart != null) {
        let rawData = await this.getDataOfAccesoryAsync(
          this.viewer,
          selectElement.value,
          linealLabels
        );
        let formattedBarData = this.formatDataOfAccesory(rawData);
        this.updateBarChart(
          widthChart,
          this.getDataByType(formattedBarData, 'Width'),
          this.getDataByType(formattedBarData, 'Width'),
          '#0082ba',
          linealLabels,
          (<HTMLSelectElement>document.getElementById('selectElement')).value,
          'Width'
        );
        this.updateBarChart(
          heightChart,
          this.getDataByType(formattedBarData, 'Height'),
          this.getDataByType(formattedBarData, 'Height'),
          '#0082ba',
          linealLabels,
          (<HTMLSelectElement>document.getElementById('selectElement')).value,
          'Height'
        );
        this.updateBarChart(
          depthChart,
          this.getDataByType(formattedBarData, 'Depth'),
          this.getDataByType(formattedBarData, 'Depth'),
          '#0082ba',
          linealLabels,
          (<HTMLSelectElement>document.getElementById('selectElement')).value,
          'Depth'
        );
        this.updatePieChart(
          chart,
          linealLabels,
          linealData,
          colores,
          widthChart,
          heightChart,
          depthChart,
          formattedBarData,
          (<HTMLSelectElement>document.getElementById('selectElement')).value
        );
      } else {
        let rawData = await this.getDataOfAccesoryAsync(
          this.viewer,
          selectElement.value,
          linealLabels
        );
        let formattedBarData = this.formatDataOfAccesory(rawData);

        widthChart = this.createBarChart(
          'WidthCanvas',
          this.getDataByType(formattedBarData, 'Width'),
          this.getDataByType(formattedBarData, 'Width'),
          '#0082ba',
          linealLabels,
          (<HTMLSelectElement>document.getElementById('selectElement')).value,
          'Width'
        );
        depthChart = this.createBarChart(
          'DepthCanvas',
          this.getDataByType(formattedBarData, 'Depth'),
          this.getDataByType(formattedBarData, 'Depth'),
          '#0082ba',
          linealLabels,
          (<HTMLSelectElement>document.getElementById('selectElement')).value,
          'Depth'
        );
        heightChart = this.createBarChart(
          'HeightCanvas',
          this.getDataByType(formattedBarData, 'Height'),
          this.getDataByType(formattedBarData, 'Height'),
          '#0082ba',
          linealLabels,
          (<HTMLSelectElement>document.getElementById('selectElement')).value,
          'Height'
        );
        chart = this.createPieChart(
          'linealCanvas',
          linealLabels,
          linealData,
          colores,
          widthChart,
          heightChart,
          depthChart,
          formattedBarData,
          (<HTMLSelectElement>document.getElementById('selectElement')).value
        );
      }
    });

    this.getAllAccessories()
      .sort()
      .forEach((element) => {
        var option = document.createElement('option');
        option.value = element;
        option.text = element;
        selectElement.appendChild(option);
      });
    var parentLinealCanvas = this.createHtmlElement(
      'div',
      'parentLinealCanvas',
      ['w-100', 'py-2'],
      null,
      '200px',
      ''
    );

    var linealCanvas = document.createElement('canvas');
    linealCanvas.id = 'linealCanvas';
    thotSubpanel.appendChild(thotNavbar);
    thotSubpanel.appendChild(thotSearchBar);
    thotSubpanel.appendChild(selectElement);
    parentLinealCanvas.appendChild(linealCanvas);
    thotSubpanel.appendChild(parentLinealCanvas);
    thotSubpanel.appendChild(accordionParent);

    var buscador = <HTMLInputElement>document.getElementById('thotSearch');

    buscador.addEventListener('keyup', () => {
      let input = buscador.value;
      let filter = input.toUpperCase();
      let lista = document.getElementById('selectElement');
      let componentes = lista.getElementsByTagName('option');

      for (let i = 0; i < componentes.length; i++) {
        let txtValue = componentes[i].value;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          componentes[i].style.display = '';
        } else {
          componentes[i].style.display = 'none';
        }
      }
    });

    var parentWidthCanvas = this.createHtmlElement(
      'canvas',
      'WidthCanvas',
      ['w-100'],
      null,
      '135px',
      ''
    );
    document.getElementById('width-accordion').appendChild(parentWidthCanvas);
    var parentHeightCanvas = this.createHtmlElement(
      'canvas',
      'HeightCanvas',
      ['w-100'],
      null,
      '135px',
      ''
    );
    document.getElementById('height-accordion').appendChild(parentHeightCanvas);
    var parentDepthCanvas = this.createHtmlElement(
      'canvas',
      'DepthCanvas',
      ['w-100'],
      null,
      '135px',
      ''
    );
    document.getElementById('depth-accordion').appendChild(parentDepthCanvas);
  }
  getDataByType(object, type) {
    let array = [];
    Object.keys(object).forEach((lineal) => {
      array = array.concat(object[lineal][type]);
    });
    return array;
  }
  truncArray(array) {
    var x = 0;
    var len = array.length;
    while (x < len) {
      array[x] = array[x].toFixed(2);
      x++;
    }
    return array;
  }
  getDataByTypeAndLineal(object, type, lineal) {
    let array = [];
    array = array.concat(object[lineal][type]);
    return array;
  }
  getDataOfAccesoryAsync(viewer, accesory, lineals) {
    let dbids = [];

    lineals.forEach((lineal) => {
      Object.keys(this.allDataFromViewer[lineal]).forEach((profile) => {
        if (
          Object.keys(this.allDataFromViewer[lineal][profile]).includes(
            accesory
          )
        ) {
          dbids = dbids.concat(
            this.allDataFromViewer[lineal][profile][accesory]
          );
        }
      });
    });
    return new Promise((resolve, reject) => {
      viewer.model.getBulkProperties2(
        dbids,
        { propFilter: ['Width', 'Height', 'Depth', 'HMYLineal'] },
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  formatDataOfAccesory(data) {
    let arrayObject = this.getHmyLinealArray(data);
    data.forEach((dbid) => {
      let actualLineal = this.getActualHMYLineal(dbid.properties);

      if (actualLineal === '') {
        actualLineal = 'unnamedSection';
      }
      let propertiesObj = this.getDbidProperties(dbid.properties);

      this.addDataToArrayFormatted(arrayObject, propertiesObj, actualLineal);
    });
    Object.keys(arrayObject).forEach((key) => {
      Object.keys(arrayObject[key]).forEach((propKey) => {
        arrayObject[key][propKey] = this.removeDuplicatesArray(
          arrayObject[key][propKey]
        );
      });
    });
    return arrayObject;
  }

  removeDuplicatesArray(array) {
    return Array.from(new Set(array));
  }
  getActualHMYLineal(properties) {
    let hmyLineal;
    properties.forEach((property) => {
      if (property['displayName'] == 'HMYLineal') {
        hmyLineal = property['displayValue'];
      }
    });
    return hmyLineal;
  }
  getDbidProperties(properties) {
    let obj = {};
    properties.forEach((property) => {
      if (property['displayName'] != 'HMYLineal') {
        obj[property['displayName']] = property['displayValue'];
      }
    });
    return obj;
  }
  getHmyLinealArray(data) {
    let HMYLinealobject = {};
    data.forEach((value) => {
      value.properties.forEach((property) => {
        if (property['displayName'] == 'HMYLineal') {
          if (property['displayValue'] === '') {
            var name = 'unnamedSection';
          } else {
            name = property['displayValue'];
          }
          HMYLinealobject[name] = {
            Width: [],
            Height: [],
            Depth: [],
          };
        }
      });
    });
    return HMYLinealobject;
  }
  addDataToArrayFormatted(formattedArray, obj, position) {
    if (obj['Height']) {
      formattedArray[position]['Height'].push(obj['Height']);
    }
    if (obj['Width']) {
      formattedArray[position]['Width'].push(obj['Width']);
    }
    if (obj['Depth']) {
      formattedArray[position]['Depth'].push(obj['Depth']);
    }
  }

  getLinealContainingAccesory(accesory) {
    let colores = JSON.parse(localStorage.getItem('colors'));
    let indexColores = 0;
    let lineals = [];
    Object.keys(this.allDataFromViewer).forEach((lineal) => {
      Object.keys(this.allDataFromViewer[lineal]).forEach((profile) => {
        if (
          Object.keys(this.allDataFromViewer[lineal][profile]).includes(
            accesory
          )
        ) {
          var encontrado = false;
          lineals.forEach((val) => {
            if (val[0] == lineal) {
              val[1] =
                val[1] +
                this.allDataFromViewer[lineal][profile][accesory].length;
              encontrado = true;
            }
          });
          if (!encontrado) {
            lineals.push([
              lineal,
              this.allDataFromViewer[lineal][profile][accesory].length,
              this.rgbToHex(
                colores[indexColores].r,
                colores[indexColores].g,
                colores[indexColores].b
              ),
            ]);
            indexColores++;
          }
        }
      });
    });
    return lineals.sort((a, b) => {
      if (a[1] > b[1]) {
        return -1;
      }
      if (b[1] > a[1]) {
        return 1;
      }
      return 0;
    });
  }
  updateBarChart(
    chart: Chart,
    labels,
    data,
    colores,
    lineal,
    description,
    type
  ) {
    chart.data.datasets[0].data = data;
    chart.data.datasets[0].backgroundColor = colores;
    chart.data.labels = labels;
    chart.options.onClick = (evt, item) => {
      console.log(data[item[0]['index']]);
      this.selectLinealWidthAsync(
        lineal,
        data[item[0]['index']],
        description,
        type
      );
    };
    chart.update();
  }

  getTypeArrayFromDataLineal(data, type, lineal) {
    let returnArray;
    data.forEach((val) => {
      if (val['HmyLineal'] == lineal) {
        returnArray.push(val[type]);
      }
    });
    return returnArray;
  }
  selectLinealWidthAsync(lineals, width, description, type) {
    let dbidArray = [];
    console.log(lineals, width, description, type);
    this.viewer.model.getBulkProperties(
      [],
      ['HMYLineal', type, 'Description'],
      (ret) => {
        ret.forEach((dbid) => {
          let isLineal = false;
          let isProp = false;
          let isDescription = false;
          dbid.properties.forEach((property) => {
            lineals.forEach((lineal) => {
              if (property.displayValue == lineal) {
                isLineal = true;
              }
            });

            if (
              property.displayValue == width &&
              property.displayName == type
            ) {
              isProp = true;
            }
            if (property.displayValue == description) {
              isDescription = true;
            }
          });
          if (isProp && isLineal && isDescription) {
            dbidArray.push(dbid.dbId);
          }
        });
        console.log(dbidArray);
        this.viewer.select(dbidArray);
        this.viewer.fitToView(dbidArray);
      }
    );
  }
  createBarChart(id: string, labels, data, colores, lineal, description, type) {
    let _this = this;
    var ctx = (<HTMLCanvasElement>document.getElementById(id)).getContext('2d');
    return new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [{ data: data, backgroundColor: colores }],
      },
      options: {
        onClick: (evt, item) => {
          console.log(data[item[0]['index']]);
          _this.selectLinealWidthAsync(
            lineal,
            data[item[0]['index']],
            description,
            type
          );
        },
        responsive: true,

        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });
  }
  updatePieChart(
    chart: Chart,
    labels,
    data,
    colores,
    widthChart,
    heightChart,
    depthChart,
    formattedData,
    description
  ) {
    let _this = this;
    chart.data.datasets[0].data = data;
    chart.data.datasets[0].backgroundColor = colores;
    chart.data.labels = labels;
    chart.options.onClick = function (evt, item) {
      console.log(labels[item[0]['index']]);
      _this.updateBarChart(
        heightChart,
        _this.getDataByTypeAndLineal(
          formattedData,
          'Height',
          labels[item[0]['index']]
        ),
        _this.getDataByTypeAndLineal(
          formattedData,
          'Height',
          labels[item[0]['index']]
        ),
        item[0]['element']['options']['backgroundColor'],
        [labels[item[0]['index']]],
        description,
        'Height'
      );
      _this.updateBarChart(
        depthChart,
        _this.getDataByTypeAndLineal(
          formattedData,
          'Depth',
          labels[item[0]['index']]
        ),
        _this.getDataByTypeAndLineal(
          formattedData,
          'Depth',
          labels[item[0]['index']]
        ),
        item[0]['element']['options']['backgroundColor'],
        [labels[item[0]['index']]],
        description,
        'Depth'
      );
      _this.updateBarChart(
        widthChart,
        _this.getDataByTypeAndLineal(
          formattedData,
          'Width',
          labels[item[0]['index']]
        ),
        _this.getDataByTypeAndLineal(
          formattedData,
          'Width',
          labels[item[0]['index']]
        ),
        item[0]['element']['options']['backgroundColor'],
        [labels[item[0]['index']]],
        description,
        'Width'
      );
    };
    chart.update();
  }
  createPieChart(
    id: string,
    labels,
    data,
    colores,
    widthChart,
    heightChart,
    depthChart,
    formattedData,
    description
  ) {
    var ctx = (<HTMLCanvasElement>document.getElementById(id)).getContext('2d');
    let _this = this;
    return new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [{ data: data, backgroundColor: colores }],
      },
      options: {
        indexAxis: 'y',
        onClick: function (evt, item) {
          console.log(labels[item[0]['index']]);
          _this.updateBarChart(
            heightChart,
            _this.getDataByTypeAndLineal(
              formattedData,
              'Height',
              labels[item[0]['index']]
            ),
            _this.getDataByTypeAndLineal(
              formattedData,
              'Height',
              labels[item[0]['index']]
            ),
            item[0]['element']['options']['backgroundColor'],
            [labels[item[0]['index']]],
            description,
            'Height'
          );
          _this.updateBarChart(
            depthChart,
            _this.getDataByTypeAndLineal(
              formattedData,
              'Depth',
              labels[item[0]['index']]
            ),
            _this.getDataByTypeAndLineal(
              formattedData,
              'Depth',
              labels[item[0]['index']]
            ),
            item[0]['element']['options']['backgroundColor'],
            [labels[item[0]['index']]],
            description,
            'Depth'
          );
          _this.updateBarChart(
            widthChart,
            _this.getDataByTypeAndLineal(
              formattedData,
              'Width',
              labels[item[0]['index']]
            ),
            _this.getDataByTypeAndLineal(
              formattedData,
              'Width',
              labels[item[0]['index']]
            ),
            item[0]['element']['options']['backgroundColor'],
            [labels[item[0]['index']]],
            description,
            'Width'
          );
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: { y: { ticks: { color: '#0082ba' } } },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });
  }
  getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }
  getAllAccessories() {
    var accessories = [];

    Object.keys(this.allDataFromViewer).forEach((lineal) => {
      Object.keys(this.allDataFromViewer[lineal]).forEach((profile) => {
        accessories = accessories.concat(
          Object.keys(this.allDataFromViewer[lineal][profile])
        );
      });
    });

    return Array.from(new Set(accessories));
  }

  //Trata los datos recibidos por el getBulkProperties
  formatDataFromBulkProperties(arrayPropertiesUnformatted) {
    var formattedPropertiesArray = [];
    //Recorremos el array sin formatear
    arrayPropertiesUnformatted.forEach(function (element) {
      //En caso de que HMYLineal no sea undefined lo tratamos y lo almacenamos.
      if (
        element.properties.find((x) => x.displayName == 'HMYLineal') !=
        undefined
      ) {
        //Encontramos el HMYLineal del elemento
        var HMYLineal = element.properties.find(
          (x) => x.displayName == 'HMYLineal'
        );
        //Si está vació el HMYLineal le asignamos unnamedSection
        if (HMYLineal.displayValue === '')
          HMYLineal.displayValue = 'unnamedSection';
        //Encontramos el HmyProfile del elemento
        var HMYProfile = element.properties.find(
          (x) => x.displayName == 'HMYProfile'
        );
        //Si está vacío lo nombramos como unnamedProfile
        if (HMYProfile.displayValue === '')
          HMYProfile.displayValue = 'unnamedProfile';
        //Encontramos la descripcion del elemento
        var Description = element.properties.find(
          (x) => x.displayName == 'Description'
        );
        if (Description == null) {
          Description = element.properties.find(
            (x) => x.displayName == 'Descripción'
          );
        }
        //Si está vacía lo nombramos como unnamedDescription
        if (Description.displayValue === '')
          Description.displayValue = 'unnamedDescription';
        //Este if inicializa el array de objetos en cada posicion en caso de que no existe. Si existe lo añade.
        if (!formattedPropertiesArray[HMYLineal.displayValue])
          formattedPropertiesArray[HMYLineal.displayValue] = [];
        if (
          !formattedPropertiesArray[HMYLineal.displayValue][
            HMYProfile.displayValue
          ]
        )
          formattedPropertiesArray[HMYLineal.displayValue][
            HMYProfile.displayValue
          ] = [];
        if (
          !formattedPropertiesArray[HMYLineal.displayValue][
            HMYProfile.displayValue
          ][Description.displayValue]
        )
          formattedPropertiesArray[HMYLineal.displayValue][
            HMYProfile.displayValue
          ][Description.displayValue] = [];

        formattedPropertiesArray[HMYLineal.displayValue][
          HMYProfile.displayValue
        ][Description.displayValue].push(element.dbId);
      }
    });
    //Asignamos a la variable del objeto el valor de las propiedades formateadas.
    this.allDataFromViewer = formattedPropertiesArray;
  }
  //Obtenemos la data desde el visor
  getDataFromViewer(viewer) {
    viewer.model.getBulkProperties(
      null,
      ['HMYLineal', 'HMYProfile', 'Description'],
      (res) => this.formatDataFromBulkProperties(res)
    );
  }
}
