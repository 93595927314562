import { Item } from './item';
import { Project } from './project';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
//import { environment } from 'src/environments/environment';
import { envar } from 'src/environments/environment.prod';
import { analyzeAndValidateNgModules } from '@angular/compiler';

@Injectable({
  providedIn: 'root',
})
export class ForgeService {
  public token: string = '';
  private postColorArrayUrl: string = envar.apiUrl + 'api/hmy/cwa/colors';
  private projectsUrl: string = envar.apiUrl + 'api/hmy/cwa/projects/';
  //S3 Testing
  private s3Url: string = envar.apiUrl + 'api/hmy/cwa/projects/models3/';
  private getPercentageUrl: string =
    envar.apiUrl + 'api/hmy/cwa/projects/model-percentage';
  private subject = new Subject<any>();
  private getHmyColors: string = envar.apiUrl + 'api/hmy/cwa/colors';
  constructor(private http: HttpClient) {
    // this.saveSessionColors();
  }

  saveSessionColors() {
    this.getListColors().subscribe((colors) => {
      localStorage.setItem('colors', JSON.stringify(colors));
    });
  }

  //S3 Testing  change the s3url to projects URL   ------------------------ !!!!!!!!!!!!!
  sendModel(data) {
    return this.http.post(this.projectsUrl + 'model', data);
  }
  deleteModel(model: Item) {
    return this.http.delete(
      this.projectsUrl +
        encodeURI(model.bucketKey) +
        '/model/' +
        encodeURI(model.objectName)
    );
  }
  getPercentage(urn): Observable<any> {
    return this.http.post(this.getPercentageUrl, urn);
  }
  createProject(bucket): Observable<any> {
    return this.http.post(this.projectsUrl, bucket);
  }
  deleteProject(data: string): Observable<any> {
    return this.http.delete(this.projectsUrl + data);
  }
  getProjects(): Observable<Project[]> {
    return this.http.get<Project[]>(this.projectsUrl);
  }
  sendClickItemEvent(value) {
    this.subject.next(value);
  }
  getClickItemEvent() {
    return this.subject;
  }
  getItems(project: Project): Observable<Item[]> {
    const url = this.projectsUrl + project.bucketName + '/400';
    return this.http.get<Item[]>(url);
  }
  getListColors(): Observable<any> {
    return this.http.get(this.getHmyColors);
  }
  postColorArray(data): Observable<any> {
    return this.http.post(this.postColorArrayUrl, data);
  }
}
